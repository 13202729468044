import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router";
import img_pack from "../img/img";
import { AdminStoreCouponsRedRootBase, AdminStoreCouponsRoot, AdminStoreRedRootBase } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import DatePicker from "./components/DatePicker";
import GetStore from "../API/GetStore";
import CouponsRedTabs from "./components/CouponsRedTabs";
import { format } from "date-fns";

const AdminStoreCouponsRedPage = () => {

    const [couponsRedTabsData, setCouponsRedTabsData] = useState({
        type: [],
        value: '',
        date: new Date(),

        min_order: '',
        max_order: '',
        // incompatibility: false,
        for_product_without_discounts: false,
        // products: [],
        // exclude_products: [],
        // product_categories: [],
        // exclude_categories: [],

        coupon_limit: '',
        limit_for_product: '',
    })

    const [publishDate, setPublishDate] = useState(new Date());

    const {idParam} = useParams()

    const location = useLocation()
    const navigate = useNavigate()

    const isEdit = location.pathname.includes(AdminStoreCouponsRedRootBase)

    // Базовые запросы \ первичный рендер

    // Получение поста при изменении поста

    const [GetStoreCoupon] = useFetching( async (id) => {
        const res = await GetStore.GetStoreCoupon(id)
        // console.log('GetStoreCoupon complete:' , res.data.data)
        const form = document.querySelector('.new_articles_form')
        const coupon = res.data.data
        form.elements.code.value = coupon?.code

        setCouponsRedTabsData(prevState => ({
            ...prevState,
            type: [{value: coupon.type_discount, name: coupon.type_discount === 'SUM' ? 'Сумма скидки' : 'Процент скидки'}] || prevState.type,
            value: coupon.coupon_value || prevState.value,
            date: coupon.coupon_expiration_date || prevState.date,

            min_order: coupon.min_order || prevState.min_order,
            max_order: coupon.max_order || prevState.max_order,
            // incompatibility: coupon.incompatibility || prevState.incompatibility,
            for_product_without_discounts: coupon.for_product_without_discounts || prevState.for_product_without_discounts,
            // products: coupon.products.map((el) => {return {value: el.slug, name: el.name}}) || prevState.products,
            // exclude_products: coupon.exclude_products.map((el) => {return {value: el.slug, name: el.name}}) || prevState.exclude_products,
            // product_categories: coupon.product_categories.map((el) => {return {value: el.slug, name: el.name}}) || prevState.product_categories,
            // exclude_categories: coupon.exclude_categories.map((el) => {return {value: el.slug, name: el.name}}) || prevState.exclude_categories,

            coupon_limit: coupon.coupon_limit || prevState.coupon_limit,
            limit_for_product: coupon.limit_for_product || prevState.limit_for_product,

        }));
    })

    useEffect(() => {
        if(idParam && isEdit) {
            GetStoreCoupon(idParam)
        }
    },[idParam])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    // // // // Отправление формы \ создание поста \ обновление поста

    const [AddNewStoreCoupon] = useFetching( async (data) => {
        const res = await GetStore.AddNewStoreCoupon(data)
        // console.log('AddNewStoreCoupon complete:' , res.data)
        navigate(`${AdminStoreCouponsRoot}`)
        return res;
    })

    const [UpdateCoupon] = useFetching( async (data, id) => {
        const res = await GetStore.UpdateCoupon(data, id)
        // console.log('UpdateCoupon complete:' , res.data)
        navigate(`${AdminStoreCouponsRoot}`)
        return res;
    })
    
    function submit(e) {
        e.preventDefault()

        const formdata = new FormData();
        formdata.append("code", e.target.elements.code.value);

        formdata.append("type_discount", couponsRedTabsData.type[0]?.value || '');
        formdata.append("coupon_value", couponsRedTabsData.value || '');
        formdata.append("coupon_expiration_date", format(new Date(couponsRedTabsData.date), 'yyyy-MM-dd') || '');

        formdata.append("min_order", couponsRedTabsData.min_order || '');
        formdata.append("max_order", couponsRedTabsData.max_order || '');
        // formdata.append("incompatibility", couponsRedTabsData.incompatibility || false);
        formdata.append("for_product_without_discounts", couponsRedTabsData.for_product_without_discounts || false);
        // couponsRedTabsData.products.forEach(el => {
        //     formdata.append("products", el.value);
        // });
        // couponsRedTabsData.exclude_products.forEach(el => {
        //     formdata.append("exclude_products", el.value);
        // });
        // couponsRedTabsData.product_categories.forEach(el => {
        //     formdata.append("product_categories", el.value);
        // });
        // couponsRedTabsData.exclude_categories.forEach(el => {
        //     formdata.append("exclude_categories", el.value);
        // });

        formdata.append("coupon_limit", couponsRedTabsData.coupon_limit || '');
        formdata.append("limit_for_product", couponsRedTabsData.limit_for_product || '');
        formdata.append("status", 'PB');
    
        if(idParam && isEdit) {
            UpdateCoupon(formdata, idParam)
        } else {
            AddNewStoreCoupon(formdata)
        }
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <div className="col-5">
                    <input name="code" className="base-input h-16-400-i gr7" placeholder="Код купона" type="text" required/>
                </div>
                <div className="h-16-500-i" style={{padding: '10px'}}>Данные товара</div>
                <CouponsRedTabs couponsRedTabsData={couponsRedTabsData} setCouponsRedTabsData={setCouponsRedTabsData} />
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
                <div className="new_articles_r_sidebar_block">
                    <div className="col-20">
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Дата публикации</span>
                            <DatePicker date={publishDate} setDate={setPublishDate} className={'h-12-400-i gr7'}/>
                        </div>
                    </div>
                </div>

            </div>
        </form>
        
    )
}

export default AdminStoreCouponsRedPage;