import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import { AdminContactRedRootBase, AdminStoreBrandsRedRoot, AdminStoreBrandsRedRootBase, AdminStoreBrandsRoot, AdminStoreCatRedRootBase, AdminStoreCatRoot, egoda_url, feedbackStatusMap } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";
import EditorComponent from "./components/EditorCompoenent";
import GetFeedback from "../API/GetFeedback";
import { format } from "date-fns";
import DatePicker from "./components/DatePicker";

const AdminContactRedPage = () => {

    // Первичные запросы
    
    const [status, setStatus] = useState([]);
    const [isRed, setIsRed] = useState(false);
    const [requestDetail, setRequestDetail] = useState('');
    // const [itemPodata, setItemPodata] = useState({title: 'Заявка будет удалена', desc: 'Вы уверены, что хотите удалить заявку безвозвратно?'});
    const {idParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminContactRedRootBase)
    const navigate = useNavigate()
    
    const [GetFeedbackRequest] = useFetching( async (id) => {
        const res = await GetFeedback.GetFeedbackRequest(id)
        setRequestDetail(res.data)
        // console.log('GetFeedbackRequest complete:' , res.data)
        setStatus([{value: res.data.status, name: (feedbackStatusMap[res.data.status]?.text || "Неизвестный статус") }])
    })
        
    useEffect(() => {
        if(isEdit && idParam) {
            GetFeedbackRequest(idParam)
        }
    },[])
    
    const [UpdateRequest] = useFetching( async (data) => {
        const res = await GetFeedback.UpdateRequest(data, requestDetail?.id)
        // console.log('UpdateRequest complete:' , res.data)
        setIsRed(false)
        return res;
    })

    function submit(e) {
        e.preventDefault()

        const data = new FormData();
        data.append("name", e.target.elements.name.value || '');
        data.append("tel", e.target.elements.tel.value || '');
        data.append("email", e.target.elements.email.value || '');
        data.append("comment", e.target.elements.comment.value || '');
        data.append("status", status[0].value || '');

        if(isRed) {
            UpdateRequest(data)
        }
        
    }

    return (
        <div className="admin_content_base" style={{maxWidth: '700px'}}>
            <form onSubmit={submit} className="new_category_form">
                <div className="row-20-js">
                    <span className="h-18-500-i gr7">Заявка  "Перезвоните мне"</span>
                    {!isRed ?
                    <button key={1} className="admin_save_btn" type='button' onClick={() => !isRed && setIsRed(true)}>
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{'Редактировать'}</span>
                    </button>
                    : 
                    <button className="admin_save_btn 1" type='submit'>
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{'Сохранить'}</span>
                    </button>
                    }
                </div>
                <div className="contactRed_table base_content_1">
                    <table>
                        <tbody>
                            <tr>    
                                <td className="h-16-400-i">ФИО</td>
                                <td>{isRed ? <input name="name" type="text" className="base-input" value={requestDetail?.name || ''} onChange={(e) => setRequestDetail({...requestDetail, name: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{requestDetail?.name}</div>}</td>
                            </tr>
                            <tr>    
                                <td className="h-16-400-i">Телефон</td>
                                <td>{isRed ? <input name="tel" type="text" className="base-input" value={requestDetail?.tel || ''} onChange={(e) => setRequestDetail({...requestDetail, tel: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{requestDetail?.tel}</div>}</td>
                            </tr>
                            <tr>    
                                <td className="h-16-400-i">Email </td>
                                <td>{isRed ? <input name="email" type="text" className="base-input" value={requestDetail?.email || ''} onChange={(e) => setRequestDetail({...requestDetail, email: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{requestDetail?.email}</div>}</td>
                            </tr>
                            <tr>    
                                <td className="h-16-400-i">Статус</td>
                                <td>{isRed ? <CustomSelect allOption={[{id:1, name: 'Новый', slug: 'NEW'},{id:2, name: 'В работе', slug: 'WORK'},{id:3, name: 'Обработан', slug: 'PROC'},{id:4, name: 'Отказ', slug: 'CANC'}]} ActiveItems={status} setActiveItems={setStatus} single={true} enclosure={false} placeholder={'Выбрать статус'}/> : <div className={`h-12-500-i flr ${feedbackStatusMap[status[0]?.value]?.className || 'ba'}`}><div/><span>{feedbackStatusMap[status[0]?.value]?.text || "Неизвестный статус"}</span></div>}</td>
                            </tr>
                            <tr>    
                                <td className="h-16-400-i">Дата</td>
                                <td className="txtr">{isRed ? <DatePicker date={requestDetail?.created} setDate={(el) => setRequestDetail({...requestDetail, created: el})} className={'h-12-400-i gr7'}/> : <div style={{textAlign: 'right'}} className="h-14-400-i">{requestDetail?.created && format(new Date(requestDetail?.created), 'dd.MM.yyyy HH:mm') }</div>}</td>
                            </tr>
                            <tr>    
                                <td className="h-16-400-i">Дополнительно</td>
                                <td>{isRed ? <input name="comment" type="text" className="base-input" value={requestDetail?.comment || ''} onChange={(e) => setRequestDetail({...requestDetail, comment: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{requestDetail?.comment}</div>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    )
}

export default AdminContactRedPage;