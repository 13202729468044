import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import img_pack from "../img/img";
import GetMediafiles from "../API/GetMediafiles";
import { useFetching } from "../hooks/UseFetching";
import { AdminMediafilesRedRoot, AdminMediafilesRedRootBase, egoda_url } from "../utils/consts";

const AdminMediafilesPage = () => {

    const [allFiles, setAllFiles] = useState([])
    const [totalFiles, setTotalFiles] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)

    // Добавить файлы
    const [FileUpload, loading, error] = useFetching( async (data) => {
        const res = await GetMediafiles.FileUpload(data)
        // console.log('FileUpload complete:' , res.data)
        if(currentPage > 1) {
            setCurrentPage(1)
        } else {
            GetAllFiles(1,'')
        }
        return res;
    })
    const [GetAllFiles] = useFetching( async (page, category_slug) => {
        const res = await GetMediafiles.GetAllFiles(page, category_slug)
        // console.log('GetAllFiles complete:' , res.data)
        if(currentPage > 1) {
            setAllFiles([...allFiles, ...res.data.data])
        } else {
            setAllFiles(res.data.data)
            setTotalFiles(res.data.total)
            setTotalPages(res.data.total_pages)
            setCurrentPage(res.data.current_page)
        }
    })

    // useEffect(() => {
    //     GetAllFiles(1,'')
    // },[])

    useEffect(() => {
        if(currentPage > 1) {
            GetAllFiles(currentPage,'')
        } else {
            GetAllFiles(1,'')
        }
    },[currentPage])

    // useEffect(() => {
    //     console.log('allFiles', allFiles)
    // },[allFiles])

    // function clickInputFile(e) {
    //     e.currentTarget.querySelector('input[type=file]').click()
    // }
    function fileInputChange(e) {
        const filesArray = Array.from(e.target.files);
        const formdata = new FormData();
        filesArray.forEach(el => {
            formdata.append("file", el);
        });
        FileUpload(formdata)
    }

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Всего</div>
                    <span className="h-12-500-i ba-or">{totalFiles} шт</span>
                </div>
                <div className="input_file">
                    <label htmlFor="mediafiles_upload_input">
                        <img src={img_pack.upload_icon} alt="upload_icon" />
                        <span className="h-14-600-i">Добавить новый</span>
                    </label>
                    <input id="mediafiles_upload_input" type="file" multiple="multiple" onChange={fileInputChange}/>
                </div>
            </div>

            <div className="mediafiles-content">
                {allFiles.map((el) => (
                    <Link to={`${AdminMediafilesRedRootBase}${el.id}`} key={el.id} className="mediafiles-ex">
                        {el.category === "VIDEO" ?
                        <span style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>Видео</span>
                        : el.category === "DOCUMENT" ?
                        <img src={`${img_pack.file_text}`} alt="img" />
                        :
                        <img src={`${egoda_url}${el.file}`} alt="img" />
                        }
                    </Link>
                ))}
            </div>

            {(currentPage < totalPages) &&
            <div style={{padding: '25px 35px',display: 'flex' ,justifyContent: 'center'}}>
                <button className="upload_more_btn" onClick={() => setCurrentPage(currentPage + 1)}>
                    <img src={img_pack.upload_icon} alt="icon" />
                    <span>Загрузить еще</span>
                </button> 
            </div>
            }

        </div>
    )
}

export default AdminMediafilesPage;