import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({dateRange, setDateRange}) => {
  const [startDate, endDate] = dateRange;

  return (
    <div>
      <DatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => setDateRange(update)}
        dateFormat="dd.MM.yyyy"
        placeholderText="Выберите диапазон дат"
        className='base-input h-14-400-i' 
      />
    </div>
  );
};

export default DateRangePicker;
