import React, { useEffect, useRef, useState } from "react"

const CustomSelect = ({allOption, ActiveItems, setActiveItems, single=true, enclosure=false, placeholder, disabled=false }) => {

    const [customSelectOpen, setCustomSelectOpen] = useState(false)
    const customSelectListRef = useRef(null);
    const customSelectBtn = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (!customSelectListRef.current.contains(event.target) && !customSelectBtn.current.contains(event.target)) {
                setCustomSelectOpen(false)
            }
        }

        if(customSelectOpen) {
            const allSpan = customSelectListRef.current.querySelectorAll('span')
            allSpan.forEach(el => {
                if (ActiveItems?.some(item => item.value === el.getAttribute('slug'))) {
                    el.classList.add('active')
                }
            })

            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    },[customSelectOpen])

    // useEffect(() => {
    //     console.log('allOption',allOption)
    // },[allOption])

    function customSelectClick(e) {
        const cur = e.currentTarget
        const value = cur.getAttribute('slug');
        const name = cur.textContent;

        if (single) {
            const allSpan = customSelectListRef.current.querySelectorAll('span')
            allSpan.forEach(el => {
                if (el.classList.contains('active')) {
                    el.classList.remove('active')
                }
            })
            if (ActiveItems.some(item => item.value === value)) {
                setActiveItems([]);
            } else {
                setActiveItems([{value: value, name: name}]);
            }
            setCustomSelectOpen(false)
        } else {
            if (ActiveItems.some(item => item.value === value)) {
                setActiveItems([...ActiveItems.filter(item => item.value !== value)]);
            } else {
                setActiveItems([...ActiveItems, {value: value, name: name}]);
            }
        }

        if(cur.classList.contains('active')) {
            cur.classList.remove('active')
        } else {
            cur.classList.add('active')
        }

    }

    return (
        <div className={`custom_select ${disabled ? 'disabled' : ''}`}>
            <button ref={customSelectBtn} type="button" className={`custom_select_btn h-12-400-i gr7 ${customSelectOpen ? 'active' : ''}`} onClick={() => setCustomSelectOpen(!customSelectOpen)}>{single ? (ActiveItems.length > 0 ? `${ActiveItems[0].name}` : placeholder ) : placeholder}</button>
            {customSelectOpen &&
            <div className="custom_select_list" ref={customSelectListRef}>
                
                {enclosure ?
                    allOption?.map((el) => (
                        !el.parent && 
                            (el.children.length > 0 ? 
                            <div className="parent_select" key={el.id}>
                                <span className="h-12-400-i gr7" onClick={customSelectClick} slug={el.slug}>{el.name}</span>
                                {el.children?.map((child) => (
                                    (child.children.length > 0 ? 
                                        <div className="parent_select" key={child.id}>
                                            <span className="h-12-400-i gr7" onClick={customSelectClick} slug={child.slug}>{child.name}</span>
                                            {child.children?.map((child1) => (
                                                <span className="h-12-400-i gr7" key={child1.id} onClick={customSelectClick} slug={child1.slug}>{child1.name}</span>
                                            ))}
                                        </div>
                                        :
                                        <span className="h-12-400-i gr7" key={child.id} onClick={customSelectClick} slug={child.slug}>{child.name}</span>
                                    )
                                ))}
                            </div>
                            :
                            <span className="h-12-400-i gr7" key={el.id} onClick={customSelectClick} slug={el.slug}>{el.name}</span>
                            )
                    ))
                    :
                    allOption?.map((el) => (
                        <span className="h-12-400-i gr7" key={el.id} onClick={customSelectClick} slug={el.slug}>{el.name}</span>
                    ))
                }
            </div>
            }
        </div>
    )
}

export default CustomSelect;