import {React, useRef, useEffect, useContext} from "react"
import { useNavigate } from "react-router-dom"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Context } from "../../App"

const Popup = ({children}) => {
    const navigate = useNavigate()
    const modalRef = useRef()

    const {curModal, setCurModal} = useContext(Context)
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    useEffect(() => {
    const observerRefValue = modalRef.current;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    disableBodyScroll(observerRefValue);
    return () => {
        if (observerRefValue) {
            setCurModal(!curModal)
            document.body.style.paddingRight = ``;
            enableBodyScroll(observerRefValue);
        }
    };
    }, []);

    return(
        <div ref={modalRef} className={"overlay active"} onClick={()=> navigate(-1)}>
            <div className="po_container">
                <div className="popup" onClick={e => e.stopPropagation()}>
                  <div className="close_po_btn" onClick={()=> navigate(-1)}/>
                  {children}
                </div>
            </div>
        </div>
    )
}

export default Popup