import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetFeedback {
    // Добавить новую заявку
    static async AddNewContactRequest(data) {
        const response = await axios.post(`${egoda_url}/feedback/request/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Получить все заявки (пагинация)
    static async GetFeedbacksPage(page) {
        const response = await axios.get(`${egoda_url}/feedback/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Удалить Заявку
    static async DeleteFeedbackRequest(id) {
        const response = await axios.delete(`${egoda_url}/feedback/request/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Изменить заявку
    static async UpdateRequest(data, id) {
        const response = await axios.put(`${egoda_url}/feedback/request/${id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response;
    }
    // Получить одну заявку
    static async GetFeedbackRequest(id) {
        const response = await axios.get(`${egoda_url}/feedback/request/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить настройки 
    static async GetFeedbackSettings() {
        const response = await axios.get(`${egoda_url}/feedback/settings/get/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Сохранить настройки
    static async UpdateFeedbackSettings(data) {
        const response = await axios.post(`${egoda_url}/feedback/settings/post/`, data,
        {
            withCredentials: true
        });

        return response;
    }
}