import youtube_icon from '../img/icons/youtube-icon.svg'
import telegram_icon from '../img/icons/telegram-icon.svg'
import facebook_icon from '../img/icons/facebook-icon.svg'
import toggle_icon_example from '../img/icons/toggle-icon-example.svg'
import profile_icon from '../img/icons/profile-icon.svg'
import cart_icon from '../img/icons/cart-icon.svg'
import menu_icon from '../img/icons/menu.svg'
import search_icon from '../img/icons/search.svg'
import close_icon from '../img/icons/close-icon.svg'
import dpd_icon from '../img/icons/dpd-icon.svg'
import buy_icon from '../img/icons/buy-btn-icon.svg'
import prf_mail from '../img/icons/prf-mail.png'
import prf_location from '../img/icons/prf-location.png'
import prf_tel from '../img/icons/prf-tel.png'
import prf_hours from '../img/icons/prf-hours.png'
import back_icon from '../img/icons/back-icon.svg'
import notif_icon from '../img/icons/notif-icon.svg'
import logout_icon from '../img/icons/log-out.svg'
import upload_icon from '../img/icons/upload-icon.svg'
import download_icon from '../img/icons/download-icon.svg'
import trash_icon from '../img/icons/trash-icon.svg'
import save_icon from '../img/icons/save-icon.svg'
import plus_icon from '../img/icons/plus-icon.svg'
import articles_edit_icon from '../img/icons/articles-edit-icon.svg'
import articles_trash_icon from '../img/icons/articles-trash-icon.svg'
import articles_eye_icon from '../img/icons/articles-eye-icon.svg'
import product_example from '../img/adm-example.svg'
import file_text from '../img/icons/file-text.svg'

import input_disc_icon from '../img/icons/input-icon/disc.svg'
import input_help_icon from '../img/icons/input-icon/help-icon.svg'
import input_mail_icon from '../img/icons/input-icon/mail.svg'
import input_unlock_icon from '../img/icons/input-icon/unlock.svg'
import input_user_icon from '../img/icons/input-icon/user.svg'
import input_phone_icon from '../img/icons/input-icon/phone.svg'

import { ReactComponent as admSide_general_icon } from '../img/icons/admSide_icon/general-icon.svg'
import { ReactComponent as admSide_articles_icon } from '../img/icons/admSide_icon/articles-icon.svg'
import { ReactComponent as admSide_shop_icon } from '../img/icons/admSide_icon/shop-icon.svg'
import { ReactComponent as admSide_media_icon } from '../img/icons/admSide_icon/media-icon.svg'
import { ReactComponent as admSide_help_icon } from '../img/icons/admSide_icon/help-icon.svg'
import { ReactComponent as admSide_settings_icon } from '../img/icons/admSide_icon/settings-icon.svg'
import { ReactComponent as admSide_users_icon } from '../img/icons/admSide_icon/users-icon.svg'
import { ReactComponent as admSide_send_icon } from '../img/icons/admSide_icon/send.svg'
import { ReactComponent as admSide_orders_icon } from '../img/icons/admSide_icon/shopping-cart.svg'

import { ReactComponent as menu_component_icon } from '../img/icons/menu.svg'
import { ReactComponent as grid_component_icon } from '../img/icons/grid.svg'

import { ReactComponent as dollar_icon } from '../img/icons/productRed_menu_icon/dollar-icon.svg'
import { ReactComponent as package_icon } from '../img/icons/productRed_menu_icon/package-icon.svg'
import { ReactComponent as tabs_icon } from '../img/icons/productRed_menu_icon/tabs-icon.svg'
import { ReactComponent as tag_icon } from '../img/icons/productRed_menu_icon/tag-icon.svg'
import { ReactComponent as slash_icon } from '../img/icons/productRed_menu_icon/slash-icon.svg'
import { ReactComponent as tool_icon } from '../img/icons/productRed_menu_icon/tool-icon.svg'
import { ReactComponent as settings_icon } from '../img/icons/productRed_menu_icon/settings.svg'

import { ReactComponent as lock_icon } from '../img/icons/productRed_menu_icon/lock-icon.svg'
import { ReactComponent as unlock_icon } from '../img/icons/productRed_menu_icon/unlock-icon.svg'
import { ReactComponent as user_icon } from '../img/icons/productRed_menu_icon/user-icon.svg'
import { ReactComponent as truck_icon } from '../img/icons/productRed_menu_icon/truck-icon.svg'

import rSideSuccess from '../img/RsideSuccess.png'
import brand_ex from '../img/brand-ex.svg'

import eye_off from '../img/icons/cart-icon/eye-off.svg'
import phone_call from '../img/icons/cart-icon/phone-call.svg'
import shield from '../img/icons/cart-icon/shield.svg'

import exp_1 from '../img/exp-1.png'
import exp_2 from '../img/exp-2.png'
import exp_3 from '../img/exp-3.png'

import faq_block1 from '../img/faq-block.png'   
import media_ex from '../img/media-ex.jpg'

import pay from '../img/pay.png'

import contact_whatsappIcon from '../img/icons/contacts-icon/whatsapp.svg'
import contact_telegramIcon from '../img/icons/contacts-icon/telegram.svg'
import contact_mappinIcon from '../img/icons/contacts-icon/map-pin.svg'
import contact_avitoIcon from '../img/icons/contacts-icon/avito.png'

import header_searchIcon from '../img/icons/header-icon/search.svg'
import header_logo from '../img/icons/header-icon/header-logo.svg'
import header_back from '../img/icons/header-icon/header-back.png'

import footer_logo from '../img/icons/footer-icon/footer-logo.svg'

import main_infoBlock_avito from '../img/main-info-block/avito.png'
import main_infoBlock_clock from '../img/main-info-block/clock.png'
import main_infoBlock_headphones from '../img/main-info-block/headphones.png'


import prv_dollar from '../img/icons/prv-block/dollar.svg'
import prv_card from '../img/icons/prv-block/card.svg'
import prv_map from '../img/icons/prv-block/map.svg'
import prv_truck from '../img/icons/prv-block/truck.svg'

import of_ex from '../img/of-ex.png'
// prostore

const img_pack = {
    youtube_icon,telegram_icon,facebook_icon,toggle_icon_example,
    exp_1,exp_2,exp_3,
    profile_icon,cart_icon,menu_icon,search_icon,close_icon,dpd_icon,
    buy_icon,
    brand_ex,
    prf_mail,prf_location,prf_tel,prf_hours,
    input_help_icon,input_mail_icon,input_unlock_icon,input_user_icon, input_phone_icon,input_disc_icon,
    back_icon,
    faq_block1,
    notif_icon,
    upload_icon,media_ex, trash_icon,save_icon,download_icon,
    admSide_articles_icon,admSide_general_icon,admSide_help_icon,admSide_media_icon,
    admSide_settings_icon,admSide_shop_icon,admSide_users_icon,admSide_send_icon, admSide_orders_icon,
    plus_icon, 
    articles_edit_icon, articles_eye_icon, articles_trash_icon,
    dollar_icon, tabs_icon, tag_icon, package_icon, slash_icon, tool_icon,settings_icon,
    lock_icon, unlock_icon, user_icon, truck_icon,
    product_example,
    eye_off,phone_call,shield,
    pay, rSideSuccess,

    contact_telegramIcon, contact_whatsappIcon, contact_mappinIcon, contact_avitoIcon,
    header_searchIcon,
    footer_logo,
    main_infoBlock_avito, main_infoBlock_clock, main_infoBlock_headphones,
    prv_dollar, prv_card, prv_map, prv_truck,
    menu_component_icon, grid_component_icon,
    logout_icon, 
    header_logo,header_back,
    file_text,

    // prostore
    of_ex,
}

export default img_pack