import React, { useContext, useEffect, useState } from "react"
import { Context } from "../../App";
import { StorePageRoot } from "../../utils/consts";
import { useNavigate } from "react-router";

const ProductsCatNav = ({catNav, activeItems, setActiveItems}) => {

    const {windowWidth} = useContext(Context)
    const navigate = useNavigate()

    function catNavClick(e) {
        setActiveItems(e.currentTarget.textContent)
    }

    return (
       <div className="row-20-a">
            <div className="products-cat-nav">
                <button onClick={catNavClick} className={`h-14-400-j ${activeItems === 'Все' ? 'active white' : 'black'}`}>Все</button>
                {catNav?.map((el) => (
                    <button onClick={catNavClick} key={el} className={`h-14-400-j ${activeItems === el ? 'active white' : 'black'}`}>{el}</button>
                ))}
            </div>
            {windowWidth >600 &&
            <div>
                <button className="viewAll-btn h-14-400-j" onClick={() => navigate(`${StorePageRoot}`)}>Посмотреть все</button>
            </div>
            }
       </div>
    )
}

export default ProductsCatNav;