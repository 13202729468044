import { useEffect, useRef, useState } from "react";
import { useFetching } from "./UseFetching";
import GetStore from "../API/GetStore";

export const UseCatsBrandSidebar = () => {

    const [allBrandsSide, setAllBrandsSide] = useState([]);
    const [allCatsSide, setAllCatsSide] = useState([]);

    // Получение всех брендов
    const [GetStoreBrandSidebar] = useFetching( async () => {
        const res = await GetStore.GetStoreBrandSidebar()
        // console.log('GetStoreBrandSidebar complete:' , res.data)
        setAllBrandsSide(res.data.data)
    })
    // Получение всех категорий
    const [GetStoreCatsSidebar] = useFetching( async () => {
        const res = await GetStore.GetStoreCatsSidebar()
        // console.log('GetStoreCatsSidebar complete:' , res.data)
        setAllCatsSide(res.data.data)
    })

    useEffect(() => {
        GetStoreCatsSidebar()
        GetStoreBrandSidebar()
    },[])

    return {allBrandsSide, allCatsSide, GetStoreCatsSidebar, GetStoreBrandSidebar}
    
}