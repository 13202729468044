import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router";
import { useFetching } from "../../hooks/UseFetching";
import GetStore from "../../API/GetStore";

const ImportTabs = () => {

    const [selectedBtn, setSelectedBtn] = useState('1')

    const location = useLocation()
    const navigate = useNavigate()

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }
    const [Import] = useFetching( async (data) => {
        const res = await GetStore.Import(data)
        // console.log('Import complete:' , res.data)
        return res;
    }, true)

    // Получение файла с попапа медиафайлов
    useEffect(() => {
        if (location.state?.confirmFile) {
            // Логика на confirmFile
            if(location.state?.confirmFile?.file_extension === '.xlsx' || location.state?.confirmFile?.file_extension === '.xls') {
                if(location.state?.confirmFile?.id !== null && location.state?.confirmFile?.id !== '') {
                    const fileId = location.state?.confirmFile?.id
                    if(selectedBtn === '1'){
                        const formdata = new FormData();
                        formdata.append("file", fileId);
                        Import(formdata)
                    }
                }
            }
            // Обнуление состояния confirm в истории
            location.state.confirmFile = undefined
            window.history.replaceState({}, '')
        }
    }, [location.state])

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1" style={{flexWrap: 'wrap'}}>
                <button style={{minWidth: '200px', maxWidth: 'max-content'}} type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick}>
                    <div className="h-14-500-i">Обычный</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <>
            <div className="h-14-400-i">Стандартный способ загрузки. Загрузите excel файл, новые товары будут импортированы, существующие обновлены. </div>
            <div className="productRed_content base_content_1">
                <div className="mediafilesPo_input">
                    <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {file: true, isMulti: false} } })}></button>
                </div>
            </div>
            </>
            }
        </div>
    )
}

export default ImportTabs;