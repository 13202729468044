import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import { AdminStoreBrandsCatRedRootBase, AdminStoreBrandsCatRoot, AdminStoreCatRedRootBase, AdminStoreCatRoot } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";

const AdminStoreBrandCatRedPage = () => {

    // Первичные запросы

    const [slug, setSlug] = useState('')
    const [catsDetail, setCatsDetail] = useState([])
    const [catsAddSelected, setCatsAddSelected] = useState([])
    
    const [itemPodata, setItemPodata] = useState({title: 'Категория будет удалена', desc: 'Вы уверены, что хотите удалить категорию безвозвратно?'});
    const {slugParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminStoreBrandsCatRedRootBase)
    const navigate = useNavigate()
    
    const [GetBrandCategory] = useFetching( async (cat) => {
        const res = await GetStore.GetBrandCategory(cat)
        setCatsDetail(res.data)
        // console.log('GetBrandCategory complete:' , res.data)
        if (res.data) {
            const form = document.querySelector('.new_category_form')
            form.elements.cat_name.value = res.data?.name
            form.elements.cat_slug.value = res.data?.slug
            res.data.parent && setCatsAddSelected([{value: res.data.parent?.slug, name: res.data.parent?.name}])
        }
    })
        
    useEffect(() => {
        if(isEdit && slugParam) {
            GetBrandCategory(slugParam)
        }
    },[])

    // Добавление\изменение новой категории

    const [AddNewBrandCategory] = useFetching( async (catData) => {
        const res = await GetStore.AddNewBrandCategory(catData)
        // console.log('AddNewBrandCategory complete:' , res.data)
        navigate(`${AdminStoreBrandsCatRoot}`)
        return res;
    })
    
    const [UpdateBrandCategory] = useFetching( async (catData) => {
        const res = await GetStore.UpdateBrandCategory(catData, catsDetail?.id)
        // console.log('UpdateBrandCategory complete:' , res.data)
        navigate(`${AdminStoreBrandsCatRoot}`)
        return res;
    })

    function submit(e) {
        e.preventDefault()
        const name = e.target.elements.cat_name.value
        const curSlug = slugify(e.target.elements.cat_slug.value, { lower: true })
        const parent = catsAddSelected.length > 0 ? catsAddSelected[0]?.value : ''

        const catData = new FormData();
        catData.append("name", name);
        catData.append("slug", curSlug);
        catData.append("parent", parent);

        if(isEdit) {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                UpdateBrandCategory(catData)
            }
        } else {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                AddNewBrandCategory(catData)
            }
        }
        
    }

    function slugifyTitle(e) {
        const newTitle = e.target.value;
        const newSlug = slugify(newTitle, { lower: true });
        setSlug(newSlug)
    }

    // // Удаление категории
    const [DeleteBrandCategory] = useFetching( async (id) => {
        const res = await GetStore.DeleteBrandCategory(id)
        // console.log('DeleteBrandCategory complete:' , res.data)
        navigate(`${AdminStoreBrandsCatRoot}`)
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteBrandCategory(catsDetail?.id)
        }
    }, [location.state])

    return (
        <div className="admin_content_base" style={{maxWidth: '600px'}}>
            <form onSubmit={submit} className="new_category_form">
                <span className="h-18-500-i gr7">{isEdit ? 'Изменить категорию' : 'Добавить новую категорию'}</span>
                <div className="col-5">
                    <span className="h-12-600-i gr7">Название новой категории</span>
                    <input onChange={slugifyTitle} name="cat_name" className="base-input h-12-400-i gr7" placeholder="Название" type="text" required/>
                </div>
                <div className="col-5">
                    <span className="h-12-600-i gr7">Slug категории</span>
                    <input name="cat_slug" defaultValue={slug} className="base-input h-12-400-i gr7" placeholder="Slug" type="text" required/>
                </div>
                <div className="row-20-js">
                    <button className="admin_save_btn" type="submit">
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{isEdit ? 'Изменить' : 'Добавить'}</span>
                    </button>
                    {isEdit &&
                    <Link to={'/item_po'} state={{prevLocation: location, itemPodata: itemPodata}} className="admin_delete_btn">
                       <img src={img_pack.trash_icon} alt="trash_icon" />
                       <span className="h-12-600-i white">Удалить</span>
                   </Link>
                    }
                </div>
            </form>
        </div>
    )
}

export default AdminStoreBrandCatRedPage;