import {React, useRef, useEffect, useState} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import img_pack from "../../img/img"
import { useFetching } from "../../hooks/UseFetching"
import GetMediafiles from "../../API/GetMediafiles"
import { egoda_url } from "../../utils/consts"
import { format } from "date-fns"
import PlayerComponent from "./PlayerComponents"

const MediafilesPo = () => {

    const [allFiles, setAllFiles] = useState([])
    const [totalFiles, setTotalFiles] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)

    const [fileDataMulti, setFileDataMulti] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [fileDataVideo, setFileDataVideo] = useState([]);

    const [curPkFile, setCurPkFile] = useState(null);
    const [multiSelect, setMultiSelect] = useState(false);

    const [editNotifOpen, setEditNotifOpen] = useState(false);

    const location = useLocation()
    const navigate = useNavigate()

    const selectableMediafiles = useRef(null)
    const cur_form = useRef(null)

    const prevLocation = location.state?.prevLocation
    const mediafilesPodata = location.state?.mediafilesPodata
    const isMulti = mediafilesPodata?.isMulti || false
    const isVideo = mediafilesPodata?.video
    const isDocument = mediafilesPodata?.file
    const holder = mediafilesPodata?.holder

    // получить список всех файлов (по страницам)
    const [GetAllFiles] = useFetching( async (page, category_slug) => {
        const res = await GetMediafiles.GetAllFiles(page, category_slug)
        // console.log('GetAllFiles complete:' , res.data)
        if(currentPage > 1) {
            setAllFiles([...allFiles, ...res.data.data])
        } else {
            setAllFiles(res.data.data)
            setTotalFiles(res.data.total)
            setTotalPages(res.data.total_pages)
            setCurrentPage(res.data.current_page)
        }
    })
    useEffect(() => {
        if(currentPage > 1) {
            GetAllFiles(currentPage,'')
        } else {
            GetAllFiles(1,'')
        }
    },[currentPage])

    // Добавить файлы
    const [FileUpload, loading, error] = useFetching( async (data) => {
        const res = await GetMediafiles.FileUpload(data)
        // console.log('FileUpload complete:' , res.data)
        if(currentPage > 1) {
            setCurrentPage(1)
        } else {
            GetAllFiles(1,'')
        }
        return res;
    })
    function fileInputChange(e) {
        const filesArray = Array.from(e.target.files);
        const formdata = new FormData();
        filesArray.forEach(el => {
            formdata.append("file", el);
        });
        FileUpload(formdata)
    }

    // Получить выбранный файл
    const [GetFileMulti] = useFetching( async (pk) => {
        const res = await GetMediafiles.GetFile(pk)
        // console.log('GetFileMulti complete:' , res.data)
        setFileDataMulti([...fileDataMulti, res.data.data])
    })

    const [GetFile] = useFetching( async (pk) => {
        const res = await GetMediafiles.GetFile(pk)
        // console.log('GetFile complete:' , res.data)
        setFileData(res.data.data)
        cur_form.current.elements.alt.value = res.data.data?.alt
        cur_form.current.elements.title.value = res.data.data?.title
        cur_form.current.elements.sign.value = res.data.data?.label
        cur_form.current.elements.desc.value = res.data.data?.desc
    })
    function selectFile(e,pk) {
        if(editNotifOpen) {
            setEditNotifOpen(false)
        }
        const AllSelectedBtns = selectableMediafiles.current.querySelectorAll('.selectable.selected')

        if(multiSelect) {
            if (!e.currentTarget.classList.contains('multi-select')) {
                e.currentTarget.classList.add('multi-select')
                GetFileMulti(pk)
            } else {
                e.currentTarget.classList.remove('multi-select')
                setFileDataMulti(fileDataMulti?.filter(obj => obj.id !== pk));
            }
        } else {
            if (curPkFile !== pk) {
                if (!e.currentTarget.classList.contains('selected')) {
                    AllSelectedBtns.forEach((el) => {
                        el.classList.remove('selected')
                    })
                    e.currentTarget.classList.add('selected')
                }
                if(pk !== null || pk !== undefined) {
                    setCurPkFile(pk)
                    GetFile(pk)
                }
            }
        }

    }

    // Редактировать файл
    const [FileEdit] = useFetching( async (pk, data) => {
        const res = await GetMediafiles.FileEdit(pk, data)
        // console.log('FileEdit complete:' , res.data)
        setEditNotifOpen(true)
        // navigate(`${AdminMediafilesRoot}`)
        return res;
    })
    function submit(e) {
        e.preventDefault()
        const formData = new FormData();
        formData.append("alt", e.target.elements.alt.value);
        formData.append("title", e.target.elements.title.value);
        formData.append("label", e.target.elements.sign.value);
        formData.append("desc", e.target.elements.desc.value);
        FileEdit(curPkFile, formData)
    }
    
    function confirm() {
        if (!holder) {
            if(isVideo) {
                if(fileData?.id && fileData?.file) {
                    navigate(`${prevLocation.pathname}`, {state: {confirmFile: fileData}})
                }
            } else {
                if(isMulti) {
                    if(fileDataMulti?.length > 0)
                    navigate(`${prevLocation.pathname}`, {state: {confirmFile: fileDataMulti}})
                    
                } else {
                    if(fileData?.id && fileData?.file) {
                        navigate(`${prevLocation.pathname}`, {state: {confirmFile: fileData}})
                    }
                }
            }
        } else {
            if(isVideo) {
                if(fileData?.id && fileData?.file) {
                    const NewfileData = {...fileData, holder: holder}
                    navigate(`${prevLocation.pathname}`, {state: {confirmFile: NewfileData}})
                }
            } else {
                if(isMulti) {
                    if(fileDataMulti?.length > 0) {
                        const NewfileDataMulti = {...fileDataMulti, holder: holder}
                        navigate(`${prevLocation.pathname}`, {state: {confirmFile: NewfileDataMulti}})
                    }
                    
                } else {
                    const NewfileData = {...fileData, holder: holder}
                    if(fileData?.id && fileData?.file) {
                        navigate(`${prevLocation.pathname}`, {state: {confirmFile: NewfileData}})
                    }
                }
            }
        }
    }

    return(
        <div className="mediafiles_po">
            <div className="mediafiles_po_header">
                <div className="row-15-a">
                    <div className="h-18-500-i gr9">Выберите файл из медиатеки или загрузите новый</div>
                    <div className="input_file">
                        <label htmlFor="mediafiles_upload_input">
                            <img src={img_pack.upload_icon} alt="upload_icon" />
                            <span className="h-14-600-i">Добавить новый</span>
                        </label>
                        <input id="mediafiles_upload_input" type="file" multiple="multiple" onChange={fileInputChange}/>
                    </div>
                    {isMulti &&
                        <button type="button" className={`multiselect_btn ${multiSelect ? 'active' : ''}`} onClick={() => setMultiSelect(!multiSelect)}>
                            <img_pack.tabs_icon />
                            <div className="h-14-500-i">Множественный выбор</div>
                        </button>
                    }
                </div>
            </div>
            <div className="mediafiles_po_row">
                <div className="col-5">
                    <div className="mediafiles-content" ref={selectableMediafiles}>
                        {allFiles.map((el) => (
                            isVideo ? 
                            (el.category === 'VIDEO' &&
                            <button type="button" key={el.id} className="mediafiles-ex selectable" onClick={(e) => selectFile(e,el.id)}>Видео</button>)
                            : (isDocument ? 
                                (el.category === 'DOCUMENT' &&
                                <button type="button" key={el.id} className="mediafiles-ex selectable" onClick={(e) => selectFile(e,el.id)}><img src={`${img_pack.file_text}`} alt="img" /></button>)
                                :
                                ((el.category !== 'VIDEO' && el.category !== 'DOCUMENT') &&
                                <button type="button" key={el.id} className="mediafiles-ex selectable" onClick={(e) => selectFile(e,el.id)}><img src={`${egoda_url}${el.file}`} alt="img" /></button>))
                        ))}
                    </div>
                    {(currentPage < totalPages) &&
                    <div style={{padding: '10px 35px',display: 'flex' ,justifyContent: 'center'}}>
                        <button className="upload_more_btn" onClick={() => setCurrentPage(currentPage + 1)}>
                            <img src={img_pack.upload_icon} alt="icon" />
                            <span>Загрузить еще</span>
                        </button> 
                    </div>
                    }
                </div>
                <div>
                    <div className="row-10">
                        <div className="mediafiles_po_imgRed">
                            {isVideo ?
                            <PlayerComponent videoUrl={`${egoda_url}${fileData?.file}`}></PlayerComponent>
                            :(isDocument ? 
                                <img src={`${img_pack.file_text}`} alt="img" />
                                :
                                <img src={`${egoda_url}${fileData?.file}`} alt="img" />)
                            }
                        </div>
                        <div className="col" style={{wordBreak: 'break-word'}}>
                            <span className="h-12-400-i">{fileData?.file_name ? fileData.file_name : '-'}</span>
                            <span className="h-12-400-i">{fileData.created ? format(new Date(fileData.created), 'dd.MM.yyyy') : '-'}</span>
                            <span className="h-12-400-i">{fileData?.category ? fileData.category : '-'}</span>
                            <span className="h-12-400-i">{fileData?.file_extension ? fileData.file_extension : '-'}</span>
                            <span className="h-12-400-i">{fileData?.size ? fileData.size : '-'} МБ</span>
                            <span className="h-12-400-i">{fileData?.width ? fileData.width : '-'} x {fileData?.height ? fileData.height : '-'} px</span>
                        </div>
                    </div>
                    <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                    <form id="mediafiles_po_form" onSubmit={submit} className="mediafiles_po_form" ref={cur_form}>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Атрибут alt</span>
                            <input name="alt" className="h-16-400-i gr5 base-input" placeholder="Напишите атрибут alt..." type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Заголовок</span>
                            <input name="title" className="h-16-400-i gr5 base-input" placeholder="Тестовый файл" type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Подпись</span>
                            <input name="sign" className="h-16-400-i gr5 base-input" placeholder="Напишите подпись..." type="text" />
                            {/* <textarea name="sign" id="sign" className="base-input h-16-400-i gr5" placeholder="Напишите подпись..." style={{height: '60px'}} /> */}
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Описание</span>
                            <input name="desc" className="h-16-400-i gr5 base-input" placeholder="Напишите описание..." type="text" />
                            {/* <textarea name="desc" id="desc" className="base-input h-16-400-i gr5" placeholder="Напишите описание..." style={{height: '60px'}} /> */}
                        </div>
                    </form>
                    <div className="col-5">
                        <span className="h-14-500-i gr7">Ссылка на файл</span>
                        <input name="link" className="h-16-400-i gr5 base-input" type="text" defaultValue={fileData?.file} readOnly/>
                    </div>
                    {fileData?.file &&
                    <div className="row-10-a">
                        <button className="copy_clipboard_btn h-12-400-i white" type="submit" form="mediafiles_po_form">Сохранить изменения</button>
                        {editNotifOpen &&
                            <span className="h-12-400-i sus7">Сохранено!</span>
                        }
                    </div>
                    }
                </div>
            </div>
            <div className="mediafiles_po_footer">
                <button className="admin_save_btn white h-14-600-i" onClick={() => confirm()}>Выбрать</button>
            </div>
        </div>
    )
} 

export default MediafilesPo