import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetMediafiles {
    // Добавить файлы
    static async FileUpload(data) {
        const response = await axios.post(`${egoda_url}/media_manager/file/upload/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Получить файлы 
    static async GetAllFiles(page, category_slug) {
        const response = await axios.post(`${egoda_url}/media_manager/file/all/`, {
            page: page,
            category_slug: category_slug,
        }, {
            withCredentials: true
        });

        return response;
    }
    // Получить файл
    static async GetFile(pk) {
        const response = await axios.get(`${egoda_url}/media_manager/file/get/${pk}/`, {
            withCredentials: true,
        });

        return response;
    }
    // Удалить файл
    static async DeleteFile(pk) {
        const response = await axios.delete(`${egoda_url}/media_manager/file/delete/${pk}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Изменить файл
    static async FileEdit(pk, data) {
        const response = await axios.put(`${egoda_url}/media_manager/file/edit/${pk}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response;
    }

}