import React, { useEffect, useRef, useState } from "react"
import img_pack from "../../img/img"
import ToggleBtn from "./toggleBtn"
import EditorComponent from "./EditorCompoenent"
import { AdminStoreRedRootBase } from "../../utils/consts"
import { useLocation } from "react-router"
import CustomSelect from "./Custom_select"
import DatePicker from "./DatePicker"
import GetStore from "../../API/GetStore"
import { useFetching } from "../../hooks/UseFetching"

const CouponsRedTabs = ({couponsRedTabsData, setCouponsRedTabsData}) => {

    const [selectedBtn, setSelectedBtn] = useState('1')
    // const [allCats, setAllCats] = useState([])
    // const [allProducts, setAllProducts] = useState([])

    const location = useLocation()
    const isEdit = location.pathname.includes(AdminStoreRedRootBase)

    // // Получение всех товаров магазина
    // const [ProductsAllWithoutPagination] = useFetching( async (data) => {
    //     const res = await GetStore.ProductsAllWithoutPagination(data)
    //     console.log('ProductsAllWithoutPagination complete:' , res.data)
    //     res.data?.result?.length > 0 && setAllProducts(res.data.result)
    // })
    // // Получение всех категорий магазина
    // const [GetStoreCatsAll] = useFetching( async () => {
    //     const res = await GetStore.GetStoreCatsAll()
    //     console.log('GetStoreCatsAll complete:' , res.data)
    //     res.data?.data?.length > 0 && setAllCats(res.data.data)
    // })

    // // general
    // useEffect(() => {
    //     GetStoreCatsAll()
    //     ProductsAllWithoutPagination('')
    // },[])
    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1">
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick}>
                    <img_pack.dollar_icon />
                    <div className="h-14-500-i">Основные</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '2' ? 'active' : ''}`} numb={2} onClick={menuBtnClick}>
                    <img_pack.slash_icon />
                    <div className="h-14-500-i">Ограничения</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '3' ? 'active' : ''}`} numb={3} onClick={menuBtnClick}>
                    <img_pack.tool_icon />
                    <div className="h-14-500-i">Лимиты</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Тип скидки</td>
                            <td><CustomSelect allOption={[{id:1, name: 'Процент скидки', slug: 'PERCENT'},{id:2, name: 'Сумма скидки', slug: 'SUM'}]} ActiveItems={couponsRedTabsData.type} setActiveItems={(e) => setCouponsRedTabsData({...couponsRedTabsData, type: e})} single={true} enclosure={false} placeholder={'Выбрать тип'}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Величина купона</td>
                            <td><input name="promoPrice" type="text" className="base-input" value={couponsRedTabsData.value || ''} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, value: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Дата окончания купона</td>
                            <td><DatePicker date={couponsRedTabsData.date} setDate={(e) => setCouponsRedTabsData({...couponsRedTabsData, date: e})} className={'h-12-400-i gr7'}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
            {selectedBtn === '2' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Минимальный чек</td>
                            <td><input name="promoPrice" type="text" className="base-input" value={couponsRedTabsData.min_order || ''} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, min_order: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Максимальный чек</td>
                            <td><input name="promoPrice" type="text" className="base-input" value={couponsRedTabsData.max_order || ''} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, max_order: e.target.value})}/></td>
                        </tr>
                        {/* <tr>
                            <td className="h-16-400-i">Несовместимость</td>
                            <td><ToggleBtn toggleActive={couponsRedTabsData?.incompatibility} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, incompatibility: e})} /></td>
                        </tr> */}
                        <tr>
                            <td className="h-16-400-i">На товары без скидок</td>
                            <td><ToggleBtn toggleActive={couponsRedTabsData?.for_product_without_discounts} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, for_product_without_discounts: e})} /></td>
                        </tr>
                        {/* <tr>
                            <td className="h-16-400-i">Товары</td>
                            <td><CustomSelect allOption={allProducts} ActiveItems={couponsRedTabsData.products} setActiveItems={(e) => setCouponsRedTabsData({...couponsRedTabsData, products: e})} single={false} enclosure={false} placeholder={'Поиск по товарам...'}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Исключить товары</td>
                            <td><CustomSelect allOption={allProducts} ActiveItems={couponsRedTabsData.exclude_products} setActiveItems={(e) => setCouponsRedTabsData({...couponsRedTabsData, exclude_products: e})} single={false} enclosure={false} placeholder={'Поиск по товарам...'}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Категории товаров</td>
                            <td><CustomSelect allOption={allCats} ActiveItems={couponsRedTabsData.product_categories} setActiveItems={(e) => setCouponsRedTabsData({...couponsRedTabsData, product_categories: e})} single={false} enclosure={false} placeholder={'Поиск по категориям...'}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Исключить категории</td>
                            <td><CustomSelect allOption={allCats} ActiveItems={couponsRedTabsData.exclude_categories} setActiveItems={(e) => setCouponsRedTabsData({...couponsRedTabsData, exclude_categories: e})} single={false} enclosure={false} placeholder={'Поиск по категориям...'}/></td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            }
            {selectedBtn === '3' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Общий лимит купона</td>
                            <td><input name="coupon_limit" type="text" className="base-input" value={couponsRedTabsData.coupon_limit || ''} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, coupon_limit: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Лимит применения на Х товаров</td>
                            <td><input name="limit_for_product" type="text" className="base-input" value={couponsRedTabsData.limit_for_product || ''} onChange={(e) => setCouponsRedTabsData({...couponsRedTabsData, limit_for_product: e.target.value})}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

export default CouponsRedTabs;