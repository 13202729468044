import React, { useEffect, useState } from "react"
import CustomSelect from "../Custom_select"
import { useFetching } from "../../../hooks/UseFetching"
import slugify from "slugify"
import GetStore from "../../../API/GetStore"

const StoreRedCats = ({catsSelected,setCatsSelected, categorySingleSelect}) => {

    const [catsAddSelected, setCatsAddSelected] = useState([])

    const [catsAll, setCatsAll] = useState([])
    const [catsAddOpen, setCatsAddOpen] = useState(false)

    
    const [GetStoreCatsAll] = useFetching( async () => {
        const res = await GetStore.GetStoreCatsAll()
        setCatsAll(res.data.data)
        // console.log('GetStoreCatsAll complete:' , res.data)
    })

    useEffect(() => {
        GetStoreCatsAll()
    },[])

    // Добавление новой категории
    
    const [AddNewStoreCategory] = useFetching( async (catData) => {
        const res = await GetStore.AddNewStoreCategory(catData)
        GetStoreCatsAll()
        // console.log('AddNewStoreCategory complete:' , res.data)
        return res;
    })

    function addNewCategotyClick(e) {
        const name = e.target.parentNode.querySelector('[name="new_cat_name"]').value;
        const curSlug = slugify(name, { lower: true })
        const parent = catsAddSelected.length > 0 ? catsAddSelected[0]?.value : ''

        const catData = new FormData();
        catData.append("name", name);
        catData.append("slug", curSlug);
        catData.append("parent", parent);

        if (name !== null && name !== '') {
            AddNewStoreCategory(catData)
        }
    }

    return (
        <>
            <span className="h-14-500-i c-3">Категория</span>
            <CustomSelect allOption={catsAll} ActiveItems={catsSelected} setActiveItems={setCatsSelected} single={true} enclosure={true} placeholder={'Выбрать категорию'}/>
            <button type="button" className="h-12-400-i gr7 txtc" onClick={() => setCatsAddOpen(!catsAddOpen)}>Добавить категорию</button>
            {catsAddOpen &&
            <div className="cats_add_block">
                <span className="h-12-600-i gr7 txtc">Название новой категории</span>
                <input name="new_cat_name" className="base-input h-12-400-i gr7" placeholder="Название" type="text" required/>
                <span className="h-12-600-i gr7 txtc">Родительская категория</span>
                <CustomSelect allOption={catsAll} ActiveItems={catsAddSelected} setActiveItems={setCatsAddSelected}  single={true} enclosure={false} placeholder={'Выбрать категорию'}/>
                <button onClick={addNewCategotyClick} type="button" className="cats_add_btn h-12-600-i white">Добавить</button>
            </div>
            }
        </>
    )
}

export default StoreRedCats;