import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetSettings {
    // Получить настройки 
    static async GetMainSettings() {
        const response = await axios.get(`${egoda_url}/settings_site/get/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Сохранить настройки
    static async UpdateMainSettings(data) {
        const response = await axios.post(`${egoda_url}/settings_site/post/`, data,
        {
            withCredentials: true
        });

        return response;
    }
}