import React, { useEffect, useRef, useState } from "react"

const ToggleBtn = ({toggleActive, setToggleActive, onChange, fill=false}) => {

    const handleClick = () => {
        const newToggleState = !toggleActive;
        // setToggleActive(newToggleState);
        if (typeof onChange === 'function') {
            onChange(newToggleState);
        }
    };

    return (
        <button type="button" className={`toggle_base ${fill ? 'fill' :''}`} onClick={handleClick} style={toggleActive ? {background: '#2626F9'} : {}}>
            <span className={`toggle_btn ${toggleActive ? 'active' : ''}`}></span>
        </button>
    )
}

export default ToggleBtn;