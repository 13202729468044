import React, { useState, useEffect, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';

const DatePicker = ({date,setDate, className}) => {

  return (
    <div>
      <ReactDatePicker
        className={`date-input ${className}`}
        selected={date}
        onChange={(el) => setDate(el)}
        dateFormat="dd / MM / yyyy"
        placeholderText="dd / MM / yyyy"
        required
        // customInput={<CustomInput />}
      />
    </div>
     
  );
};

export default DatePicker;
