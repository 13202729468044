import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetOrder {
    // Создать заказ
    static async CreateOrder(data) {
        const response = await axios.post(`${egoda_url}/order/create/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить один заказ (token)
    static async GetOrderDetailToken(token) {
        const response = await axios.get(`${egoda_url}/order/info/${token}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить один заказ
    static async GetOrderDetail(id) {
        const response = await axios.get(`${egoda_url}/order/detail/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить список заказов (пагинация)
    static async GetOrdersList(page) {
        const response = await axios.get(`${egoda_url}/order/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Удалить заказ
    static async DeleteOrder(id) {
        const response = await axios.delete(`${egoda_url}/order/delete/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Изменить заказ
    static async UpdateOrder(data, id) {
        const response = await axios.put(`${egoda_url}/order/edit/${id}/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })

        return response;
    }
// Статус (! Получить всех статусов (dropdown))
    static async OrderStatusList() {
        const response = await axios.post(`${egoda_url}/order/status_list_dropdown/`, {},
        {
            withCredentials: true
        });

        return response;
    }

    // Получить настройки 
    static async GetOrderSettings() {
        const response = await axios.get(`${egoda_url}/order/settings/get/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Сохранить настройки
    static async UpdateOrderSettings(data) {
        const response = await axios.post(`${egoda_url}/order/settings/post/`, data,
        {
            withCredentials: true
        });

        return response;
    }

}