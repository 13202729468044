import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router";
import img_pack from "../img/img";
import { AdminUsersRedRootBase, AdminUsersRoot, egoda_url } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetUsers from "../API/GetUsers";
import UsersRedTabs from "./components/UsersRedTabs";

const AdminUsersRedPage = () => {

    const [userRedTabsData, setUserRedTabsData] = useState({
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone: '',
        role: [],

        city: '',
        postal_code: '',
        address: '',

        password: '',

    })

    const [nickname, setNickname] = useState('');
    const {idParam} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const isEdit = location.pathname.includes(AdminUsersRedRootBase)

    // Базовые запросы \ первичный рендер

    const [GetUser] = useFetching( async (idParam) => {
        const res = await GetUsers.GetUser(idParam)
        // console.log('GetUser complete:' , res.data)
        const user = res.data.detail
        setNickname(user?.username)
        setUserRedTabsData(prevState => ({
            ...prevState,
            first_name: user?.profile?.first_name || prevState.first_name,
            last_name: user?.profile?.last_name || prevState.last_name,
            middle_name: user?.profile?.middle_name || prevState.middle_name,
            email: user?.email || prevState.email,
            phone: user?.profile?.phone || prevState.phone,
            role: user?.profile?.role ? [{value: user?.profile?.role?.slug , name: user?.profile?.role?.name}] : [],

            city: user?.profile?.city || prevState.city,
            postal_code: user?.profile?.postal_code || prevState.postal_code,
            address: user?.profile?.address || prevState.address,
            
            password: user?.password || prevState.password,
          }));
    })

    useEffect(() => {
        if(idParam && isEdit) {
            GetUser(idParam)
        }
    },[idParam])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    const [CreateUser] = useFetching( async (data) => {
        const res = await GetUsers.CreateUser(data)
        // console.log('CreateUser complete:' , res.data)
        navigate(`${AdminUsersRoot}`)
        return res;
    })
    const [UpdateUser] = useFetching( async (data) => {
        const res = await GetUsers.UpdateUser(data, idParam)
        // console.log('UpdateUser complete:' , res.data)
        navigate(`${AdminUsersRoot}`)
        return res;
    })
    
    function submit(e) {
        e.preventDefault()
        const data = {
            "username": nickname || '',
            "first_name": userRedTabsData?.first_name || '',
            "last_name": userRedTabsData?.last_name || '',
            "email": userRedTabsData?.email || '',
            ...(userRedTabsData?.password ? { "password": userRedTabsData.password } : {}),
            "profile": {
                "first_name": userRedTabsData?.first_name || '',
                "last_name": userRedTabsData?.last_name || '',
                "middle_name": userRedTabsData?.middle_name || '',
                "phone": userRedTabsData?.phone || '',
                "city": userRedTabsData?.city || '',
                "address": userRedTabsData?.address || '',
                "postal_code": userRedTabsData?.postal_code || '', 
                "role_slug": userRedTabsData?.role[0]?.value || '',
            }
        }

        if(isEdit) {
            UpdateUser(data)
        } else {
            CreateUser(data)
        }
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <div className="col-5">
                    <input name="username" className="base-input h-16-400-i gr7" placeholder="Никнейм" type="text" required value={nickname || ''} onChange={(e) => setNickname(e.target.value)}/>
                </div>
                <div className="h-16-500-i" style={{padding: '10px'}}>Данные пользователя</div>
                <UsersRedTabs userRedTabsData={userRedTabsData} setUserRedTabsData={setUserRedTabsData}></UsersRedTabs>
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
            </div>
        </form>
        
    )
}

export default AdminUsersRedPage;