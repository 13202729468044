import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetUsers {
    // ! Получить список пользователей (пагинация)
    static async GetUsersList(page) {
        const response = await axios.get(`${egoda_url}/user/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Получить пользователя
    static async GetUser(id) {
        const response = await axios.get(`${egoda_url}/user/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Получить список ролей (пагинация)
    static async GetUsersRolePage(page) {
        const response = await axios.get(`${egoda_url}/user/role/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Получить список ролей (без пагинации)
    static async GetUsersRoleAll() {
        const response = await axios.get(`${egoda_url}/user/role/listdropdown/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Удалить пользователя
    static async DeleteUser(id) {
        const response = await axios.delete(`${egoda_url}/user/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Создать пользователя
    static async CreateUser(data) {
        const response = await axios.post(`${egoda_url}/user/create/`, data, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Редактировать пользователя
    static async UpdateUser(data, id) {
        const response = await axios.put(`${egoda_url}/user/${id}/`, data, {
            withCredentials: true
        })

        return response;
    }
// Роли
    // ! Получить роль
    static async GetRole(slug) {
        const response = await axios.get(`${egoda_url}/user/role/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Удалить пользователя
    static async DeleteRole(slug) {
        const response = await axios.delete(`${egoda_url}/user/role/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Создать роль
    static async CreateRole(data) {
        const response = await axios.post(`${egoda_url}/user/role_add/create/`, data, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Редактировать роль
    static async UpdateRole(data, slug) {
        const response = await axios.put(`${egoda_url}/user/role/${slug}/`, data, {
            withCredentials: true
        })

        return response;
    }
}