import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import globalFunction, { AdminContactRedRootBase, AdminOrderRedRootBase, AdminStoreBrandsRedRoot, AdminStoreBrandsRedRootBase, AdminStoreBrandsRoot, AdminStoreCatRedRootBase, AdminStoreCatRoot, ProductPageRootBase, egoda_url, feedbackStatusMap } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";
import EditorComponent from "./components/EditorCompoenent";
import GetFeedback from "../API/GetFeedback";
import { format } from "date-fns";
import DatePicker from "./components/DatePicker";
import GetOrder from "../API/GetOrder";

const AdminOrderRedPage = () => {

    // Первичные запросы
    
    const [statusMap, setStatusMap] = useState([]);
    const [status, setStatus] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [isRed, setIsRed] = useState(false);

    const [orderDetail, setOrderDetail] = useState('');
    // const [itemPodata, setItemPodata] = useState({title: 'Заявка будет удалена', desc: 'Вы уверены, что хотите удалить заявку безвозвратно?'});
    const {idParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminOrderRedRootBase)
    const navigate = useNavigate()
    
    const [GetOrderDetail] = useFetching( async (id) => {
        const res = await GetOrder.GetOrderDetail(id)
        setOrderDetail(res.data.detail)
        // console.log('GetOrderDetail complete:' , res.data)
        setStatus([{value: res.data.detail?.status?.slug, name: res.data.detail?.status?.status_name}])
        setDelivery([{value: res.data.detail?.delivery, name: (res.data.detail?.delivery === 'PICKUP' ? 'Самовывоз' : res.data.detail?.delivery === 'DC' ? 'Доставка по Челябинску' : 'Доставка по России')}])
    })

    const [OrderStatusList] = useFetching( async () => {
        const res = await GetOrder.OrderStatusList()
        // console.log('OrderStatusList complete:' , res.data)
        setStatusMap( (res.data?.result).map((el) => ({id: el.id, name: el.status_name, slug: el.slug})) )
    })
        
    useEffect(() => {
        if(isEdit && idParam) {
            GetOrderDetail(idParam)
        }
        OrderStatusList()
    },[])
    
    const [UpdateOrder] = useFetching( async (data) => {
        const res = await GetOrder.UpdateOrder(data, orderDetail?.id)
        // console.log('UpdateOrder complete:' , res.data)
        setIsRed(false)
        return res;
    })

    function submit(e) {
        e.preventDefault()

        const data = {
            "first_name": orderDetail.first_name || '',
            "last_name": orderDetail.last_name || '',
            "patronymic": orderDetail.patronymic || '',
            "email": orderDetail.email || '',
            "address": orderDetail.address || '',
            "postal_code": orderDetail.postal_code || '',
            "city": orderDetail.city || '',
            "status_slug": status[0]?.value || '',
            "delivery": delivery[0]?.value || '',

            "payment_status ": orderDetail?.payment_status  || '',
            "payment_type": orderDetail?.payment_type || '',
            "payment_purpose": orderDetail?.payment_purpose  || '',
            "operation_id  ": orderDetail?.operation_id  || '',
            "payment_link   ": orderDetail?.payment_link   || '',

            "items": orderDetail?.items.map((el) => ({
                "product": el.product.id,
                "price": el.price,
                "quantity": el.quantity
            }) ) || [],
        }

        if(isRed) {
            UpdateOrder(data)
        }
        
    }

    return (
        <div className="admin_content_base" >
            <form onSubmit={submit} className="new_category_form">
                <div className="row-20-js">
                    <span className="h-18-500-i gr7">Заказ №{orderDetail?.id}</span>
                    {!isRed ?
                    <button key={1} className="admin_save_btn" type='button' onClick={() => !isRed && setIsRed(true)}>
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{'Редактировать'}</span>
                    </button>
                    : 
                    <button className="admin_save_btn 1" type='submit'>
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{'Сохранить'}</span>
                    </button>
                    }
                </div>
                <div style={{display: 'flex', gap: '50px'}}>
                    <div className="col-20" style={{flex: '1'}}>
                        <div className="h-16-700-i gr7">Детали заказа:</div>
                        <div className="col">
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Заказ №</div>
                                <div className="h-14-400-i" style={{textAlign: 'right'}}>{orderDetail.id}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Статус</div>
                                <div className="h-14-400-i" style={{width: '221px'}}>{isRed ? <CustomSelect allOption={statusMap} ActiveItems={status} setActiveItems={setStatus} single={true} enclosure={false} placeholder={'Выбрать статус'}/> : <div className={`h-12-500-i flr ba `} style={{textAlign: 'right'}}><div/><span>{status[0]?.name}</span></div>}</div>
                            </div>
                            {(orderDetail?.operation_id  !== null && orderDetail?.operation_id  !== '') &&
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}> Идентификатор платежной операции</div>
                                    <div className="h-14-400-i" style={{textAlign: 'right'}}>{orderDetail?.operation_id }</div>
                                </div>
                            }
                            {(orderDetail?.payment_type !== null && orderDetail?.payment_type !== '') &&
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Тип оплаты</div>
                                <div className="h-14-400-i" style={{textAlign: 'right'}}>{
                                orderDetail?.payment_type === 'sbp' ? 'Оплата СБП по QR коду,' : 
                                orderDetail?.payment_type === 'card ' ? 'Оплата картой' : '-'}</div>
                            </div>
                            }
                            {(orderDetail?.payment_purpose !== null && orderDetail?.payment_purpose !== '') &&
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Назначение платежа</div>
                                <div className="h-14-400-i" style={{textAlign: 'right'}}>{orderDetail?.payment_purpose}</div>
                            </div>
                            }
                            {(orderDetail?.payment_link !== null && orderDetail?.payment_link !== '' && orderDetail?.payment_status === 'CREATED') &&
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Ссылка на оплату</div>
                                <Link to={orderDetail?.payment_link} className="base-btn h-14-400-i white">Оплатить</Link>
                            </div>
                            }
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Доставка</div>
                                <div className="h-14-400-i" style={{width: '221px'}}>{isRed ? <CustomSelect allOption={[{id: 1, slug: 'PICKUP', name: 'Самовывоз'},{id: 2, slug: 'DC', name: 'Доставка по Челябинску'}, {id: 3, slug: 'DR', name: 'Доставка по России'}]} ActiveItems={delivery} setActiveItems={setDelivery} single={true} enclosure={false} placeholder={'Выбрать доставку'}/> : <div style={{textAlign: 'right'}} className={`h-12-500-i flr ba `}><div/><span>{delivery[0]?.name}</span></div>}</div>
                            </div>
                        </div>
                        <div className="h-16-700-i gr7">Информация о клиенте</div>
                        <div className="col">
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Имя</div>
                                <div className="h-14-400-i">{isRed ? <input name="name" type="text" className="base-input" value={orderDetail?.first_name || ''} onChange={(e) => setOrderDetail({...orderDetail, first_name: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.first_name}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Фамилия</div>
                                <div className="h-14-400-i">{isRed ? <input name="last_name" type="text" className="base-input" value={orderDetail?.last_name || ''} onChange={(e) => setOrderDetail({...orderDetail, last_name: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.last_name}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Отчество</div>
                                <div className="h-14-400-i">{isRed ? <input name="patronymic" type="text" className="base-input" value={orderDetail?.patronymic || ''} onChange={(e) => setOrderDetail({...orderDetail, patronymic: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.patronymic}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Телефон</div>
                                <div className="h-14-400-i">{isRed ? <input name="tel" type="text" className="base-input" value={orderDetail?.tel || ''} onChange={(e) => setOrderDetail({...orderDetail, tel: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.tel}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Email</div>
                                <div className="h-14-400-i">{isRed ? <input name="email" type="text" className="base-input" value={orderDetail?.email || ''} onChange={(e) => setOrderDetail({...orderDetail, email: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.email}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Город</div>
                                <div className="h-14-400-i">{isRed ? <input name="city" type="text" className="base-input" value={orderDetail?.city || ''} onChange={(e) => setOrderDetail({...orderDetail, city: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.city}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Индекс</div>
                                <div className="h-14-400-i">{isRed ? <input name="postalCode" type="text" className="base-input" value={orderDetail?.postal_code || ''} onChange={(e) => setOrderDetail({...orderDetail, postal_code: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.postal_code}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Адрес доставки</div>
                                <div className="h-14-400-i">{isRed ? <input name="address" type="text" className="base-input" value={orderDetail?.address || ''} onChange={(e) => setOrderDetail({...orderDetail, address: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.address}</div>}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-20" style={{flex: '1'}}>
                        <div className="h-16-700-i gr7">Содержимое заказа</div>
                        <div className="col">
                            <div style={{padding: '20px 10px', display: 'flex', gap: '20px', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i">Товар</div>
                                <div className="h-14-400-i">Сумма</div>
                            </div>
                            {orderDetail?.items?.map((el) => (
                                <div key={el.product.id} className="order-product-container">
                                    <div style={{borderBottom: '1px solid #D0D5DD'}}>
                                        <div className="row-20-a">
                                            <Link to={`${ProductPageRootBase}${el.product.category_slug}/${el.product.slug}`}>
                                                {el.product.image_url ?
                                                    <img style={{height: '50px', width: '50px', borderRadius: '10px', objectFit: 'contain'}} src={`${egoda_url}${el.product.image_url}`} alt="img" />
                                                    : <img style={{height: '50px', width: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                                } 
                                            </Link>
                                            <div className="col">
                                                <div className="h-14-400-i gr8" style={{width: '100%'}}>{el.product.name}</div>
                                                {el.product.item_number &&
                                                    <div className="row-5-a"><span className="h-12-600-i gr5">Артикул:</span><span className="h-12-400-i gr5">{el.product?.item_number}</span></div>
                                                }
                                                {el.quantity &&
                                                    <div className="row-5-a"><span className="h-12-600-i gr5">Количество:</span><span className="h-12-400-i gr5">{el.quantity} шт.</span></div>
                                                }
                                            </div>
                                        </div>
                                        <div className="h-14-400-i" style={{minWidth: 'max-content'}}>{globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽</div>
                                    </div>
                                </div>
                            ))}
                            <div style={{padding: '20px 0', gap: '20px', display: 'flex', justifyContent: 'space-between'}}>
                                <div className="h-14-700-i" style={{alignSelf: 'center'}}>ИТОГО:</div>
                                <div className="h-30-400-i">{orderDetail?.total_price_with_discount && globalFunction.withSpaces(globalFunction.split(orderDetail?.total_price_with_discount)[0])} ₽</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AdminOrderRedPage;