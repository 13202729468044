import React, { useEffect, useRef, useState } from "react"
import img_pack from "../../img/img"
import ToggleBtn from "./toggleBtn"
import EditorComponent from "./EditorCompoenent"
import globalFunction, { AdminStoreRedRootBase } from "../../utils/consts"
import { useLocation } from "react-router"
import CustomSelect from "./Custom_select"
import { useFetching } from "../../hooks/UseFetching"
import GetUsers from "../../API/GetUsers"

const RolesRedTabs = ({rolesRedTabsData, setRolesRedTabsData}) => {

    const [selectedBtn, setSelectedBtn] = useState('1')
    const location = useLocation()

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1" style={{width: 'max-content'}}>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick} style={{minWidth: '200px'}}>
                    <img_pack.unlock_icon />
                    <div className="h-14-500-i">Доступы</div>
                </button>

            </div>
            {selectedBtn === '1' &&
            <>
            <div className="h-16-500-i" style={{padding: '10px'}}>Доступ в админ панель</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Есть доступ</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.access_admin_panel} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, access_admin_panel: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Общая сводка</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_dashboard} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_dashboard: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_dashboard} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_dashboard: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Магазин</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_shop} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_shop: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_shop} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_shop: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Услуги</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_services} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_services: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_services} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_services: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            {/* <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Специалисты</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_specialists} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_specialists: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_specialists} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_specialists: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Заказы</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_orders} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_orders: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_orders} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_orders: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Обратная связь</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_feedback} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_feedback: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_feedback} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_feedback: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Статьи</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_articles} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_articles: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_articles} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_articles: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Медиафайлы</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_media} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_media: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_media} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_media: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Пользователи</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_users} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_users: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_users} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_users: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Рассылка</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_newsletters} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_newsletters: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_newsletters} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_newsletters: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            <div className="h-16-500-i" style={{padding: '10px'}}>Раздел Настройки</div>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Смотреть раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.view_settings} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, view_settings: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Редактировать раздел</td>
                            <td><ToggleBtn toggleActive={rolesRedTabsData?.edit_settings} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, edit_settings: e})} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
            }
                 
        </div>
    )
}

export default RolesRedTabs;