import React, { useContext, useEffect, useState } from "react"
import { Context } from "../../App";
import img_pack from "../../img/img";
import globalFunction, { AddToCart, ProductPageRootBase, egoda_url } from "../../utils/consts";
import { Link } from "react-router-dom";
import LazyImage from "./LazyImage";

const ProductExample = ({res}) => {

    const {windowWidth,AddCart} = useContext(Context)
    const [count, setCount] = useState(1);

    function AddToCart(id, quantity, override) {
        const data = new FormData();
        data.append("product_id", id || '');
        data.append("quantity", quantity || '');
        data.append("override", override || false);

        // console.log('asd', id, quantity, override)
    
        AddCart(data)
    };


    return (
        <div className="product-example">
            <Link to={`${ProductPageRootBase}${res.category_slug}/${res.slug}`}><div className="product-example-img">
                {res.image_url ?
                <LazyImage src={`${egoda_url}${res.image_url}`} alt="product_img" /> 
                :<LazyImage style={{objectFit: 'contain', maxHeight: '160px', maxWidth: '160px'}} src={`${img_pack.product_example}`} alt="img" />}
            </div></Link>
            <div className="product-example-content">
                <div className="h-14-400-i gr8 txtc"><Link to={`${ProductPageRootBase}${res.category_slug}/${res.slug}`}>{res?.name}</Link></div>

                <div className="col">
                    {/* <div className="h-12-400-i gr5">В наличии ({res?.quantity || 0} шт)</div> */}
                    <div className="h-14-700-i">{globalFunction.withSpaces(globalFunction.split(res?.price_order)[0])} ₽</div>
                </div>
                <Link style={{maxWidth: 'max-content'}} to={`${ProductPageRootBase}${res.category_slug}/${res.slug}`} className="base-btn h-14-400-j white">Подробнее</Link>
            </div>  
        </div>
    )
}

export default ProductExample;