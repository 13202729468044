import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";
import { Link } from "react-router-dom";

const PublicOfferPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ интернет-магазина prostore-protechno.ru</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">ИП Стрельников Максим Алексеевич, на основании сведений, содержащихся в Выписке из Единого государственного реестра индивидуальных предпринимателей, ОГРНИП 324745600015950 от 30.01.2024, зарегистрированного Межрайонной инспекцией Федеральной налоговой службы № 17 по Челябинской области, именуемый в дальнейшем «Продавец», и пользователь услуг Интернет-магазина, именуемый в дальнейшем «Покупатель», настоящим договором определяют условия заключения договора "купли-продажи" дистанционным способом на сайте https://prostore-protechno.ru</div>
                                <div className="h-14-400-i c-2">Продавец сохраняет за собой право в одностороннем порядке изменять условия договора-оферты. Изменения вступают в силу с момента их публикации на сайте интернет-магазина prostore-protechno.ru</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">1. ОПРЕДЕЛЕНИЯ И ТЕРМИНЫ</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">1.1 В целях надлежащего толкования условий договора – оферты нижеприведённые термины используются в следующем значении:</div>
                                <div className="h-14-400-i c-2">1.1.1 Оферта - публичное предложение Продавца, адресованное любому физическому или юридическому лицу, заключить с ним договор купли-продажи (далее – «Договор») на существующих условиях, содержащихся в Договоре, включая все его Приложения.</div>
                                <div className="h-14-400-i c-2">1.1.2 Покупатель – любое физическое или юридическое лицо, обладающее гражданской право дееспособностью, заключившее с Продавцом Договор на условиях, содержащихся в Договоре.</div>
                                <div className="h-14-400-i c-2">1.1.3 Акцепт – полное и безоговорочное принятие Покупателем условий Договора.</div>
                                <div className="h-14-400-i c-2">1.1.4 Интернет-магазин – официальный сайт Продавца (https://prostore-protechno.ru), предназначенный для заключения договоров купли-продажи на основании ознакомления Покупателя с предложенным Продавцом описанием Товара и/или представленным на фотоснимках, посредством сети интернет, исключающей возможность непосредственного ознакомления Покупателем с Товаром (дистанционный способ продажи товара).</div>
                                <div className="h-14-400-i c-2">1.1.5 Товар – перечень наименований ассортимента в Интернет-магазине Продавца на сайте https://prostore-protechno.ru</div>
                                <div className="h-14-400-i c-2">1.1.6 Заказ – отдельные позиции из ассортиментного перечня Товара, указанные Покупателем при оформлении заявки в интернет-магазине Продавца.</div>
                                <div className="h-14-400-i c-2">1.1.7 Консультант – сотрудник интернет-магазина Продавца, предоставляющий Покупателю информационно-консультационные услуги по оформлению Заказа Товара.</div>
                                <div className="h-14-400-i c-2">1.1.8 Подтверждённый заказ – заказ, по которому между Продавцом и Покупателем достигнуто соглашение по наименованиям, количеству, стоимости товаров и срокам их поставки, и который подтверждён сотрудником интернет-магазина Продавца.</div>
                                <div className="h-14-400-i c-2">1.2 В договоре-оферте могут быть использованы термины, не определённые в п.1.1. В этом случае толкование такого термина производится в соответствии с текстом договора-оферты. В случае отсутствия однозначного толкования термина в тексте, следует руководствоваться толкованием термина, определённым на сайте Интернет-магазина https://prostore-protechno.ru в сети Интернет, а в части не урегулированной им – действующим законодательством РФ, либо применяемым в обычаях делового оборота.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">2. ОБЩИЕ ПОЛОЖЕНИЯ</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">2.1 ИП Стрельников Максим Алексеевич публикует настоящий договор купли-продажи, который в случае, если достигнуто соглашение по наименованиям, количеству, стоимости товаров и срокам их поставки, является публичным договором-офертой (предложением) в адрес физических и юридических лиц, в соответствии со ст.435 и пунктом 2 статьи 437 Гражданского кодекса Российской Федерации (далее - ГК РФ).</div>
                                <div className="h-14-400-i c-2">2.2 Настоящая публичная оферта (именуемая в дальнейшем «Оферта») определяет все существенные условия договора между ИП Стрельников Максим Алексеевич и лицом, акцептовавшим Оферту.</div>
                                <div className="h-14-400-i c-2">2.3 Настоящий договор заключается между Покупателем и Интернет-магазином в момент оформления заказа.</div>
                                <div className="h-14-400-i c-2">2.4 Оферта может быть принята любым физическим или юридическим лицом, имеющим намерение приобрести Товар, предоставляемый Интернет-магазином.</div>
                                <div className="h-14-400-i c-2">2.5 При принятии условий настоящего договора (т.е. публичной оферты Интернет-магазина), физическое или юридическое лицо, производящее акцепт оферты, становится Покупателем.</div>
                                <div className="h-14-400-i c-2">2.6 Покупатель безоговорочно принимает все условия, содержащиеся в оферте в целом (т.е. в полном объёме и без исключения).</div>
                                <div className="h-14-400-i c-2">2.7 Акцептом является момент выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату (или предоплату) товара.</div>
                                <div className="h-14-400-i c-2">2.8 Обязанности Продавца определяются условиями настоящей оферты.</div>
                                <div className="h-14-400-i c-2">2.9 Продавец предоставляет Покупателю полную и достоверную информацию о Товаре, включая информацию об основных потребительских свойствах Товара, месте изготовления, а также информацию о гарантийном сроке и сроке годности Товара на сайте Интернет магазина.</div>
                                <div className="h-14-400-i c-2">2.10 Продавец в одностороннем порядке принимает и изменяет условия Договора. В отношениях между Продавцом и Покупателем применяются положения Договора, действующие на момент Акцепта.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">3. ПРЕДМЕТ ДОГОВОРА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">3.1 Продавец обязуется передать в собственность Покупателя Товар, а Покупатель обязуется принять и оплатить Товар на условиях настоящего договора.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">4. ОФОРМЛЕНИЕ И ЗАКАЗ ТОВАРА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">4.1 Заказ Товара осуществляется Покупателем через сервис Интернет-магазина https://prostore-protechno.ru</div>
                                <div className="h-14-400-i c-2">4.1.1 Покупатель проинформирован, что цена и наличие Товара изменяется на Сайте круглосуточно без предварительного уведомления об этом и указываются в индивидуальном статусе и подробной карточке Товара, отображаемым в каталоге Продавца на Сайте.</div>
                                <div className="h-14-400-i c-2">4.1.2 Покупатель проинформирован, что Товар и его необходимое количество резервируется за Покупателем только после подтверждения Заказа Продавцом.</div>
                                <div className="h-14-400-i c-2">4.1.3 Заказ считается подтвержденным после получения Покупателем уведомления о подтверждении с указанием номера Заказа. До получения подтверждения Заказа, с указанием его номера, договор дистанционной купли-продажи считается не заключенным.</div>
                                <div className="h-14-400-i c-2">4.1.4 Покупатель также соглашаетесь с тем, что в случае редактирования Покупателем состава Заказа до его обработки Продавцом, цены на Товары, а также наличие Товара могут изменяться, как это определено п.4.1.1. настоящего Договора. Под редактированием в данном случае понимается добавление/удаление Товара в Заказе.</div>
                                <div className="h-14-400-i c-2">4.1.5 Покупатель соглашается, что при невозможности связаться с Покупателем в течение 2 календарных дней, считая дату оформления Заказа, для его согласования, данный Заказ будет аннулирован.</div>
                                <div className="h-14-400-i c-2">4.1.6 В случае отсутствия заказанных Товаров на складе Продавца, в том числе по причинам, не зависящим от Продавца, Продавец вправе аннулировать указанный Товар из Заказа Покупателя, и уведомить об этом Покупателя путем направления электронного сообщения по адресу, указанному при формировании Покупателем Заказа (при наличии) либо по контактному телефону, указанному Покупателем при оформлении Заказа.</div>
                                <div className="h-14-400-i c-2">4.1.7 Заказ считается исполненным в момент фактической передачи Товаров, входящих в состав Заказа, Покупателю в пункте выдачи товара или в момент доставки, на основании выданной Продавцом или транспортной компанией, осуществляющей доставку Заказа, накладной либо квитанции на доставку на определенные Товары под роспись Покупателя. Либо иного документа, подтверждающего факт получения товара покупателем. По исполнении Заказа обязательства Продавца перед Покупателем считаются выполненными.</div>
                                <div className="h-14-400-i c-2">4.1.8 При отсутствии Товара в наличии Продавец уведомляет Покупателя об этом. Настоящим Покупатель проинформирован о случаях, при которых возможны технические ошибки, а именно: на сайте Продавца имеется информация о наличии Товара, однако в наличии такой Товар отсутствует. В связи с этим Продавец уведомляет Покупателя о невозможности выполнить Заказ и предлагает иные варианты Заказа, либо возвращает денежные средства Покупателю (в случае предоплаты за Товар).</div>
                                <div className="h-14-400-i c-2">4.2 При регистрации в Интернет-магазине Продавца, Покупатель обязуется предоставить следующую регистрационную информацию: фамилия, имя, адрес электронной почты, контактный телефон, адрес доставки (в случае доставки товара).</div>
                                <div className="h-14-400-i c-2">4.3 Предоставляя свои персональные данные при Заказе Товара, Покупатель соглашается на их обработку Продавцом в соответствии со ст.3, ст.9, ст.15 ФЗ «О персональных данных» от 27.07.2006 г.</div>
                                <div className="h-14-400-i c-2">4.4 Продавец использует предоставленную информацию для выполнения своих обязательств перед Покупателем.</div>
                                <div className="h-14-400-i c-2">4.5 Продавец обязуется не разглашать полученную от Покупателя информацию. Не считается нарушением предоставление Продавцом информации третьим лицам, действующим на основании договора с Продавцом (в том числе, службам доставки), для исполнения обязательств перед Покупателем.</div>
                                <div className="h-14-400-i c-2">4.6 Продавец не несёт ответственности за содержание и достоверность Информации, предоставленной Покупателем при оформлении Заказа.</div>
                                <div className="h-14-400-i c-2">4.7 Покупатель несёт ответственность за сообщение чужих, либо недостоверных персональных данных при регистрации в Интернет-магазине Продавца.</div>
                                <div className="h-14-400-i c-2">4.8 В течение 1 (одного) рабочего дня с момента поступления Заказа, Продавец связывается с Покупателем посредством звонка или направления сообщения на указанный телефон для согласования наличия Товара по Заказу, сроков и способов оплаты заказа, сроков исполнения заказа, условий передачи товара. После согласования указанных условий Заказ считается подтверждённым.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">5. СТОИМОСТЬ И ОПЛАТА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">5.1 Цена, взимаемая за Товар, является ценой на момент размещения Заказа и указывается на Сайте Продавца в описании Товара.</div>
                                <div className="h-14-400-i c-2">5.2 Продавец вправе изменять цены на товары в любое время без предварительного уведомления. Цена товара действительна на момент подтверждения заказа Продавцом. По подтверждённым Продавцом Заказам цена на Товар изменению не подлежит.</div>
                                <div className="h-14-400-i c-2">5.3 Оплата Товара и доставки производится в виде полной оплаты или при получении Товара.</div>
                                <div className="h-14-400-i c-2">5.3.1 Покупатель вправе оплатить Товар следующими способами:- Оплата банковской картой при получении Товара.- Оплата наличными денежными средствами при получении Товара.- Оплата банковской картой на сайте Интернет-магазина Продавца.</div>
                                <div className="h-14-400-i c-2">5.4 Обязательства Покупателя по оплате Товара считаются исполненными с момента зачисления денежных средств на расчётный счёт Продавца.</div>
                                <div className="h-14-400-i c-2">5.5 Продавец вправе не передавать Товар до его полной оплаты.</div>
                                <div className="h-14-400-i c-2">5.6 Подтверждением оплаты является либо кассовый чек, либо иной документ, применяемый на территории Российской Федерации в соответствии с действующим законодательством РФ, подтверждающий оплату Товара.</div>
                                <div className="h-14-400-i c-2">5.7 Указанная на сайте цена на Товар, временно отсутствующий в продаже, не является окончательной. При появлении Товара в продаже цена может измениться.</div>
                                <div className="h-14-400-i c-2">5.8 В случае аннуляции полностью, либо частично предоплаченного Заказа Покупателем, стоимость аннулированного Товара возвращается Продавцом Покупателю тем способом, которым Товар изначально был предоплачен, за вычетом фактически понесённых Продавцом затрат по доставке Заказа до клиента и/или пункта самовывоза, а также возврата на склад Продавца.</div>
                                <div className="h-14-400-i c-2">5.9 Срок оплаты товара равен 5 дням с момента уведомления о готовности товара к передаче.</div>
                                <div className="h-14-400-i c-2">5.10 В случае если оплата не внесена покупателем в обусловленный срок, продавец имеет право в одностороннем порядке изменить цену товара.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">6. ХРАНЕНИЕ</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">6.1 Продавец обязуется хранить Заказ Покупателя в течение срока хранения, указанного при оформлении Заказа. Неполучение Заказа в указанный срок считается отказом Покупателя от договора купли-продажи и является основанием для аннулирования Заказа Продавцом. Если неполученный Заказ был предоплачен, денежные средства возвращаются Покупателю в порядке, предусмотренном Настоящими Правилами.</div>
                                <div className="h-14-400-i c-2">6.2 Стандартный срок хранения товара в течение 7 (семи) календарных дней с момента поступления Заказа.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">7. ОСОБЕННОСТИ ПРОДАЖИ УЦЕННОГО ТОВАРА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">7.1 Продавец вправе предлагать к продаже Товар, имеющий недостатки, указываемые в описании Товара на Сайте, а также в сопроводительной документации к Товару.</div>
                                <div className="h-14-400-i c-2">7.2 В случае обнаружения Покупателем недостатков, которые не были оговорены Продавцом при продаже Товара, Покупатель вправе предъявлять требования о безвозмездном устранении недостатков, замене или расторжении Договора, предусмотренные законом.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">8. ВОЗВРАТ ТОВАРА НАДЛЕЖАЩЕГО КАЧЕСТВА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">8.1 Покупатель вправе отказаться от заказанного Товара в любое время до его получения, а после передачи Товара – в течение 7 дней.</div>
                                <div className="h-14-400-i c-2">8.2 Возврат Товара надлежащего качества возможен в случае, если Товар не был в употреблении, сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного Товара.</div>
                                <div className="h-14-400-i c-2">8.3 При отказе от Товара надлежащего качества Покупателю возвращается стоимость Товара. Стоимость доставки Покупателю не компенсируется.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">9. ВОЗВРАТ ТОВАРА НЕНАДЛЕЖАЩЕГО КАЧЕСТВА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">9.1 На все товары производителем устанавливается гарантийный срок. Условия и объем гарантийных обязательств определяется в письменном гарантийном обязательстве, передаваемом Покупателю при получении им Товара.</div>
                                <div className="h-14-400-i c-2">9.2 В случае предъявления претензий к качеству Товара Продавец для определения причин возникновения недостатков вправе провести за свой счёт экспертизу. Если в результате экспертизы Товара установлено, что его недостатки возникли вследствие обстоятельств, за которые не отвечает Продавец, Покупатель обязан возместить Продавцу расходы на проведение экспертизы, а также связанные с её проведением расходы на хранение и транспортировку Товара.</div>
                                <div className="h-14-400-i c-2">9.3 Требования о возврате уплаченной за Товар денежной суммы подлежат удовлетворению на основании заявления Покупателя в течение 10 дней со дня предъявления соответствующего требования.</div>
                                <div className="h-14-400-i c-2">9.4 Покупатель обязуется вернуть памятки, документы, предоставленные вместе с Товаром.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">10. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">10.1 Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих обязательств по Договору, если докажут, что надлежащее исполнение оказалось невозможным вследствие обстоятельств непреодолимой силы, которые стороны не могли предвидеть и избежать форс-мажорных обстоятельств. При этом наличие форс-мажорных обстоятельств продлевает срок исполнения Сторонами принятых на себя по Договору обязательств до прекращения соответствующих форс-мажорных обстоятельств.</div>
                                <div className="h-14-400-i c-2">10.2 О возникновении обстоятельств непреодолимой силы сторона, подвергшаяся их действию, должна сообщить другой стороне в течение 5 рабочих дней с момента их возникновения.</div>
                                <div className="h-14-400-i c-2">10.3 В случае, если обстоятельства непреодолимой силы длятся более одного месяца, любая из сторон вправе отказаться от Договора. Договор будет считаться расторгнутым с момента получения письменного уведомления (в том числе посредством электронной почты).</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">11. ОТВЕТСТВЕННОСТЬ СТОРОН</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">11.1 Продавец не несёт ответственности за ущерб, причинённый Покупателю вследствие ненадлежащего использования Товаров, приобретённых у Продавца.</div>
                                <div className="h-14-400-i c-2">11.2 Во всех остальных случаях Стороны несут ответственность в соответствии с законодательством РФ.</div>
                                <div className="h-14-400-i c-2">11.3 Все возникающие споры Стороны будут стараться решить путём переговоров.</div>
                                <div className="h-14-400-i c-2">11.4 Все претензии по ненадлежащему исполнению Заказа Покупатель вправе направить по адресу электронной почты Продавца manager@prostore-protechno.ru, либо через форму обратной связи на сайте Интернет-магазина https://prostore-protechno.ru.</div>
                                <div className="h-14-400-i c-2">11.5 Продавец имеет право на осуществление записи телефонных переговоров с Покупателем.</div>
                                <div className="h-14-400-i c-2">11.6 Неурегулированные споры подлежат рассмотрению в судебном порядке согласно действующему законодательству РФ.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">12. СРОК ДЕЙСТВИЯ ДОГОВОРА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">12.1 Настоящий договор действует до полного выполнения Сторонами всех обязательств по нему.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">13. РЕКВИЗИТЫ ПРОДАВЦА</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">Индивидуальный предприниматель Стрельников Максим Алексеевич</div>
                                <div className="h-14-400-i c-2">Адрес: 454080, г. Челябинск, Свердловский проспект, 62</div>
                                <div className="h-14-400-i c-2">ИНН: 751505636707</div>
                                <div className="h-14-400-i c-2">ОГРНИП: 324745600015950 от 30.01.2024</div>
                                <div className="h-14-400-i c-2">Банк: ООО "Банк Точка"</div>
                                <div className="h-14-400-i c-2">р/с 40802810320000393053</div>
                                <div className="h-14-400-i c-2">к/с 30101810745374525104</div>
                                <div className="h-14-400-i c-2">БИК 044525104</div>
                                <div className="h-14-400-i c-2">manager@prostore-protechno.ru</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-14-400-i c-2">Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вы вправе отказаться от покупки Товаров, предоставляемых Продавцом.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default PublicOfferPage;