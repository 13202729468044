import React, { useCallback, useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header'; 
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Checklist from '@editorjs/checklist';
import CodeTool from '@editorjs/code';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import LinkTool from '@editorjs/link';
import Warning from '@editorjs/warning';
import Raw from '@editorjs/raw';
// import Paragraph from '@editorjs/paragraph';
// import { getTime } from "date-fns";
// import Marker from '@editorjs/marker';
// import InlineCode from '@editorjs/inline-code';
// import SimpleImage from '@editorjs/simple-image';

import { createReactEditorJS } from 'react-editor-js'

const EDITOR_JS_TOOLS = {
  header: Header,
  list: List,
  image: ImageTool,
  checklist: Checklist,
  code: CodeTool,
  quote: Quote,
  delimiter: Delimiter,
  embed: Embed,
  table: Table,
  linkTool: LinkTool,
  warning: Warning,
  raw: Raw,
}

// const initial = {
//   time: Date.now(),
//   blocks: [
//     {
//       id: 'K5pBChz5tT',
//       type: 'paragraph',
//       data: {
//         text: '213',
//       },
//     },
//   ],
//   version: '2.30.2',
// };

const EditorComponent = ({onInitialized, editorPreview, setEditorContent, holder}) => {

  const ReactEditorJS = createReactEditorJS()
  const editorCore = useRef(null)
  
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
    if (onInitialized) {
      onInitialized(); // Уведомление о завершении инициализации
    }
  }, []);

  useEffect(() => {
    const renderEditorPreview = async () => {
      if (editorCore.current && editorPreview) {
        try {
          // Добавляем таймаут перед вызовом render
          await new Promise(resolve => setTimeout(resolve, 100)); // Задержка в 100 миллисекунд
          // Удаление предыдущего содержимого и рендеринг нового
          await editorCore.current.clear();
          await editorCore.current.render(editorPreview);
          // setIsRender(true);
        } catch (error) {
          console.error('Error rendering data: ', error);
        }
      }
    };
    renderEditorPreview();
  }, [editorPreview]);

  const handleSave = React.useCallback(async () => {
    try {
      const savedData = await editorCore.current.save();
      setEditorContent(savedData);
    } catch (error) {
      console.error('Error saving data: ', error);
    }
  }, []);

  return (
    <>
      <ReactEditorJS 
      onInitialize={handleInitialize} 
      holder={holder} 
      tools={EDITOR_JS_TOOLS} 
      onChange={handleSave}
      i18n={{
        messages: {
          ui: {
            "blockTunes": {
              "toggler": {
                "Click to tune": "Нажмите, чтобы настроить",
                "or drag to move": "или перетащите"
              },
            },
            "inlineToolbar": {
              "converter": {
                "Convert to": "Конвертировать в"
              }
            },
            "toolbar": {
              "toolbox": {
                "Add": "Добавить"
              }
            }
          },
          toolNames: {
            "Text": "Параграф",
            "Heading": "Заголовок",
            "List": "Список",
            "Warning": "Примечание",
            "Checklist": "Чеклист",
            "Quote": "Цитата",
            "Code": "Код",
            "Delimiter": "Разделитель",
            "Raw HTML": "HTML-фрагмент",
            "Table": "Таблица",
            "Link": "Ссылка",
            "Marker": "Маркер",
            "Bold": "Полужирный",
            "Italic": "Курсив",
            "InlineCode": "Моноширинный",
          },
      
          tools: {
            "warning": { // <-- 'Warning' tool will accept this dictionary section
              "Title": "Название",
              "Message": "Сообщение",
            },
            "link": {
              "Add a link": "Вставьте ссылку"
            },
            "stub": {
              'The block can not be displayed correctly.': 'Блок не может быть отображен'
            }
          },
          blockTunes: {
            "delete": {
              "Delete": "Удалить"
            },
            "moveUp": {
              "Move up": "Переместить вверх"
            },
            "moveDown": {
              "Move down": "Переместить вниз"
            }
          },
        }
      }}
      >
        <div id={holder} />
      </ReactEditorJS>
    </>
  ) 
}

export default EditorComponent;
