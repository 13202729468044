import React, { useContext, useEffect, useState } from "react"
import ProductCatChoice from "./ProductCatChoice";
import PriceRangeSlider from "./PriceRangeSlider";
import { useFetching } from "../../hooks/UseFetching";
import GetStore from "../../API/GetStore";
import { useLocation } from "react-router";
import { Context } from "../../App";
import slugify from "slugify";

const FilterSearchBlock = ({searchFilters, setSelectedSearchFilters, selectedSearchFilters, rangePrice, setRangePrice, sortingProducts, query, setQuery, selectedCat, setSelectedCat, selectedBrand, setSelectedBrand, maxPrice}) => {

    const {allBrandsSide, allCatsSide} = useContext(Context)

    const location = useLocation()
    let initialBrand = location.state?.initialBrand

    useEffect(() => {
        if(initialBrand !== '' && initialBrand !== undefined && initialBrand?.slug !== '') {
            setSelectedBrand(initialBrand)
            location.state.initialBrand = ''
            window.history.replaceState({}, '')
        }
    },[initialBrand])

    function handleFilterSelection(filterName, value) {
        setSelectedSearchFilters((prevSelectedFilters) => {
            const slug = slugify(filterName, { lower: true, strict: true }); // Генерация slug из filterName
            // Проверяем, существует ли уже фильтр с таким именем и значением
            const existingFilter = prevSelectedFilters.find(
                (filter) => filter.name === filterName && filter.value === value
            );
            if (existingFilter) {
                // Если фильтр с таким именем и значением уже существует, удаляем его
                return prevSelectedFilters.filter((filter) => filter !== existingFilter);
            } else {
                // Если фильтра нет, добавляем новый
                return [
                    ...prevSelectedFilters.filter((filter) => filter.name !== filterName), // Удаляем все другие фильтры с тем же именем
                    { name: filterName, value, slug }, // Добавляем новый фильтр
                ];
            }
        });
    }
    
    return (
        <>
            <div className="h-16-700-i gr7">Поиск по товарам</div>
                <input type="text" placeholder="Поиск по товарам..." className="base-input" value={query || ''} onChange={(e) => setQuery(e.target.value)}/>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />
                <div className="h-14-400-i gr7">Цена:</div>
                <PriceRangeSlider rangePrice={rangePrice} setRangePrice={setRangePrice} minPrice={0} maxPrice={maxPrice}/>
                {searchFilters && searchFilters?.map((el) => (
                    <div key={el.name} className="col-20">
                        <div className="h-14-400-i gr7">{el.name}:</div>
                        <div className="ba_select_list">
                            {el.values?.map((value) => (
                                <button
                                    key={value}
                                    className={`ba_base h-12-500-i gr7 ${selectedSearchFilters?.some((filter) => filter.name === el.name && filter.value === value)? 'active': ''}`}
                                    onClick={() => handleFilterSelection(el.name, value)}
                                >{value}</button>
                            ))}
                        </div>
                    </div>
                ))}


                <button className="base-btn h-14-500-i white" onClick={() => sortingProducts()}>Фильтровать</button>
                <div className="h-16-700-i gr7">Категории товаров</div>
                <div className="col-10">
                    <ProductCatChoice res={allCatsSide} selectedCat={selectedCat} setSelectedCat={setSelectedCat}></ProductCatChoice>
                </div> 
        </>
    )
}

export default FilterSearchBlock;