import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import { AdminStoreCatRedRootBase, AdminStoreCatRoot } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";

const AdminStoreCatRedPage = () => {

    // Первичные запросы

    const [slug, setSlug] = useState('')
    const [catsDetail, setCatsDetail] = useState([])
    const [catsAll, setCatsAll] = useState([])
    const [catsAddSelected, setCatsAddSelected] = useState([])

    const [selectedFilters, setSelectedFilters] = useState([])
    const [catFilters, setCatFilters] = useState([])
    
    const [itemPodata, setItemPodata] = useState({title: 'Категория будет удалена', desc: 'Вы уверены, что хотите удалить категорию безвозвратно?'});
    const {slugParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminStoreCatRedRootBase)
    const navigate = useNavigate()
    
    const [UpdateCategoryFilters] = useFetching( async (slug, filters) => {
        const data = {
            filters: filters
        }
        const res = await GetStore.UpdateCategoryFilters(slug, data)
        // console.log('UpdateCategoryFilters complete:' , res.data)
        return res;
    })
    const [GetCategoryFilters] = useFetching( async (slug) => {
        const res = await GetStore.GetCategoryFilters(slug)
        console.log('GetCategoryFilters complete:' , res.data)
        const filters = res.data.detail;
        setCatFilters(filters);
        const activeFilters = filters
            .filter((filter) => filter.is_active)
            .map((filter) => filter.name);

        setSelectedFilters(activeFilters);
    })
    
    const [GetStoreCatsAll] = useFetching( async () => {
        const res = await GetStore.GetStoreCatsAll()
        setCatsAll(res.data.data)
        // console.log('GetStoreCatsAll complete:' , res.data)
    })
    
    const [GetStoreCategory] = useFetching( async (cat) => {
        const res = await GetStore.GetStoreCategory(cat)
        setCatsDetail(res.data)
        // console.log('GetStoreCategory complete:' , res.data)
        if (res.data) {
            const form = document.querySelector('.new_category_form')
            form.elements.cat_name.value = res.data?.name
            form.elements.cat_slug.value = res.data?.slug
            res.data.parent && setCatsAddSelected([{value: res.data.parent?.slug, name: res.data.parent?.name}])
        }
    })
        
    useEffect(() => {
        if(isEdit && slugParam) {
            GetStoreCategory(slugParam)
            GetCategoryFilters(slugParam)
        }
        GetStoreCatsAll()
    },[])

    // Добавление\изменение новой категории

    const [AddNewStoreCategory] = useFetching( async (catData) => {
        const res = await GetStore.AddNewStoreCategory(catData)
        // console.log('AddNewStoreCategory complete:' , res.data)
        navigate(`${AdminStoreCatRoot}`)
        return res;
    })
    
    const [UpdateStoreCategory] = useFetching( async (catData) => {
        const res = await GetStore.UpdateStoreCategory(catData, catsDetail?.id)
        // console.log('UpdateStoreCategory complete:' , res.data)
        navigate(`${AdminStoreCatRoot}`)
        return res;
    })

    function submit(e) {
        e.preventDefault()
        const name = e.target.elements.cat_name.value
        const curSlug = slugify(e.target.elements.cat_slug.value, { lower: true })
        const parent = catsAddSelected.length > 0 ? catsAddSelected[0]?.value : ''

        const catData = new FormData();
        catData.append("name", name);
        catData.append("slug", curSlug);
        catData.append("parent", parent);

        if(isEdit) {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                UpdateStoreCategory(catData)
            }
        } else {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                AddNewStoreCategory(catData)
            }
        }
        
    }

    function slugifyTitle(e) {
        const newTitle = e.target.value;
        const newSlug = slugify(newTitle, { lower: true });
        setSlug(newSlug)
    }

    // // Удаление категории
    const [DeleteStoreCategory] = useFetching( async (id) => {
        const res = await GetStore.DeleteStoreCategory(id)
        // console.log('DeleteStoreCategory complete:' , res.data)
        navigate(`${AdminStoreCatRoot}`)
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteStoreCategory(catsDetail?.id)
        }
    }, [location.state])

    function checkClick(e, name, all = false) {
        if (all) {
            setSelectedFilters((prevSelectedFilters) => {
                // Если все активны, очищаем массив, иначе добавляем все
                return prevSelectedFilters.length === catFilters.length
                    ? []
                    : catFilters.map((filter) => filter.name);
            });
        } else if (name) {
            setSelectedFilters((prevSelectedFilters) =>
                prevSelectedFilters.includes(name)
                    ? prevSelectedFilters.filter((filter) => filter !== name)
                    : [...prevSelectedFilters, name]
            );
        }
    }

    return (
        <div className="row-20">
            <div className="admin_content_base" style={{maxWidth: '600px', width: '100%'}}>
                <form onSubmit={submit} className="new_category_form">
                    <span className="h-18-500-i gr7">{isEdit ? 'Изменить категорию' : 'Добавить новую категорию'}</span>
                    <div className="col-5">
                        <span className="h-12-600-i gr7">Название новой категории</span>
                        <input onChange={slugifyTitle} name="cat_name" className="base-input h-12-400-i gr7" placeholder="Название" type="text" required/>
                    </div>
                    <div className="col-5">
                        <span className="h-12-600-i gr7">Slug категории</span>
                        <input name="cat_slug" defaultValue={slug} className="base-input h-12-400-i gr7" placeholder="Slug" type="text" required/>
                    </div>
                    <div className="col-5">
                        <span className="h-12-600-i gr7">Родительская категория</span>
                        <CustomSelect allOption={catsAll} ActiveItems={catsAddSelected} setActiveItems={setCatsAddSelected} single={true} enclosure={false} placeholder={'Выбрать категорию'}/>
                    </div>
                    <div className="row-20-js">
                        <button className="admin_save_btn" type="submit">
                            <img src={img_pack.save_icon} alt="save_icon" />
                            <span className="h-12-600-i white">{isEdit ? 'Изменить' : 'Добавить'}</span>
                        </button>
                        {isEdit &&
                        <Link to={'/item_po'} state={{prevLocation: location, itemPodata: itemPodata}} className="admin_delete_btn">
                        <img src={img_pack.trash_icon} alt="trash_icon" />
                        <span className="h-12-600-i white">Удалить</span>
                    </Link>
                        }
                    </div>
                </form>
            </div>
            {isEdit &&
            <div className="admin_content_base" style={{maxWidth: '600px', width: '100%', alignSelf: 'flex-start'}}>
                <div className="admin_content_header">
                    <div className="row-10-a">
                        <div className="h-18-500-i gr8">Фильтры</div>
                        <span className="h-12-500-i ba-or">{catFilters?.length} шт</span>
                    </div>
                    <button onClick={() => UpdateCategoryFilters(slugParam, selectedFilters)} className="base-btn h-12-600-i white">Сохранить</button>
                </div>
                <table className="articles_admin_table">
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    <span className={`check_box_mark ${(selectedFilters.length === catFilters.length && catFilters.length > 0) ? 'active' : ''}`} onClick={(e) => checkClick(e, null, true)}/>
                                    Название
                                </div>
                            </th>
                            <th>
                                <div>
                                    Значения
                                </div>
                            </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {catFilters?.map((el) => (
                            <tr key={el.name}>
                                <td>
                                    <div>
                                        <span className={`check_box_mark ${selectedFilters.includes(el.name) ? 'active' : ''}`} onClick={(e) => checkClick(e,el.name)}/>
                                        <span className="h-14-500-i gr7">{el.name}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="h-12-500-i">
                                        {
                                        el?.values?.length > 1
                                            ? el?.values?.join(', ')
                                            : el?.values?.map((value) => (
                                                <span key={value}>{value}</span>
                                            ))
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default AdminStoreCatRedPage;