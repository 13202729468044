import React, { useContext, useEffect, useRef, useState } from "react"
import img_pack from "../../img/img";
import { useFetching } from "../../hooks/UseFetching";
import GetStore from "../../API/GetStore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProductPageRootBase, StorePageRoot, egoda_url } from "../../utils/consts";

const HeaderSearch = () => {

    const location = useLocation()
    const [productsAll, setProductsAll] = useState([])
    const [query, setQuery] = useState('')
    const q_input = useRef()
    const cont = useRef(null);
    const btn = useRef(null);
    const navigate = useNavigate()

    // Получение всех товаров
    const [UsersProductsAll] = useFetching( async (productData, page) => {
        const res = await GetStore.UsersProductsAll(productData, page)
        // console.log('UsersProducts complete:' , res.data)
        setProductsAll(res.data.result)
    })

    function submit(e) {
        e.preventDefault()
        q_input.current.value !== '' && navigate(`${StorePageRoot}`, { state: { query: q_input.current.value } })
        setQuery('')
        q_input.current.value = ''
    }

    const prevqueryRef = useRef(query);
    const timerRef = useRef(null);    
    useEffect(() => {
        if (prevqueryRef.current !== query) {
            if(query !== '') {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                // Установка нового таймера
                timerRef.current = setTimeout(() => {
                    const formdata = new FormData();
                    formdata.append("query", q_input.current.value || ''); //поиск по тексту
                    formdata.append("price_from", ''); //цена от
                    formdata.append("price_to", ''); //цена до
                    formdata.append("category", ''); //категория (если есть потомки, то отображаются товары потомков в т.ч.)
                    formdata.append("sort_by", 'created_desc'); //created_desc / created_asc / price_desc / price_asc
                    formdata.append("item_quantity", 4); //кол-во итемов для вывода (дефолт 12)
                    UsersProductsAll(formdata, 1)
                }, 400);
                prevqueryRef.current = query;
            } else {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
            }
        }
    }, [query])

    const inputFocus = () => {
        if (q_input.current.value !== '' && query === '') {
            setQuery(q_input.current.value)
        }
    }

    const handleClickOutside = (event) => {
        if (cont.current && !cont.current.contains(event.target) && !btn.current.contains(event.target)) {
            setQuery('')
        }
    };
    useEffect(() => {
        // Добавляем обработчик события клика
        document.addEventListener('mousedown', handleClickOutside);
        // Убираем обработчик при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <form className={`header-search-form`} onSubmit={submit}>
                <div ref={cont} className="header-input-container">
                    <input ref={q_input} className="base-input" name="search" type="text" placeholder="Найти" onChange={(e) => setQuery(e.target.value)} onFocus={inputFocus}/>
                    {(query !== '' && productsAll?.length > 0) &&
                        <div className="header-search-result">
                            {productsAll?.map((el) => (
                                <Link to={`${ProductPageRootBase}${el.category_slug}/${el.slug}`} key={el.id} onClick={() => {setQuery(''); q_input.current.value = ''}}>
                                    {el.image_url ?
                                        <img style={{height: '50px', minWidth: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.image_url}`} alt="img" />
                                        : <img style={{height: '50px', minWidth: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                    }
                                    <div className="h-14-400-i c-2">{el.name}</div>
                                </Link>
                            ))}
                        </div>
                    }
                </div>
                <button ref={btn} type="submit"><img src={img_pack.search_icon} alt="ic" /></button>
            </form>
        </>
    )
}

export default HeaderSearch;