import React, { useContext, useEffect, useRef, useState } from "react"
import { Context } from "../App";
import globalFunction, { CartOrderPageRoot, StorePageRoot, egoda_url } from "../utils/consts";
import { useNavigate } from "react-router";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetCart from "../API/GetCart";
import ProductCounter from "./components/ProductCounter";
import { Link } from "react-router-dom";

const CartPage = () => {

    const {windowWidth,ShowCart,UpdateCart, updateQuantity,DeleteFromCart, cart, productsAll, setProductsAll} = useContext(Context)
    const navigate = useNavigate()

    const [couponOpen, setCouponOpen] = useState(false)

    function deleteProduct(id) {
        const data = new FormData();
        data.append("product_id", id || '');
        DeleteFromCart(data)
    }
    // Отмена купона
    const [RemoveCoupon] = useFetching( async (data) => {
        const res = await GetCart.RemoveCoupon(data)
        // console.log('RemoveCoupon complete:' , res.data)
        ShowCart()
        return res;
    })
    // Добавление купона
    const [ApplyCoupon] = useFetching( async (data) => {
        const res = await GetCart.ApplyCoupon(data)
        // console.log('ApplyCoupon complete:' , res.data)
        ShowCart()
        return res;
    })

    function couponSubmit(e) {
        e.preventDefault()
        const data = new FormData();
        data.append("coupon_code", e.target.elements.coupon?.value || '');

        if(e.nativeEvent.submitter.name === 'del') {
            RemoveCoupon(data)
            e.target.elements.coupon.value = ''
        } else {
            ApplyCoupon(data)
        }
    }

    return (
       <>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="cart-row">
                        <div>
                            {Object.keys(productsAll)?.length === 0 &&
                                <div className="alert-block">
                                    <span></span>
                                    <div>
                                        <div className="h-20-500-c">Ваша корзина пуста</div>
                                        <div className="h-14-400-i gr7">Чтобы оформить заказ, добавьте, пожалуйста, <Link to={StorePageRoot} className="underline">товаров из нашего магазина!</Link></div>
                                    </div>
                                </div>
                            }
                            {Object.keys(productsAll)?.length !== 0  &&
                            (windowWidth > 850 ?
                            <div className="cart-table-conatiner">
                                <table className="cart-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style={{textAlign: 'left'}}>Наименование товара</th>
                                            <th>Цена</th>
                                            <th>Количество</th>
                                            <th>Сумма</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(productsAll)?.length > 0 && Object.values(productsAll)?.map((el) => (
                                            <tr key={el.product_id} className="h-14-400-i">
                                                <td className="txtc"> 
                                                    {el.image_url ?
                                                        <img style={{height: '50px', minWidth: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.image_url}`} alt="img" />
                                                        : <img style={{height: '50px', minWidth: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                                    }
                                                </td>
                                                <td className="t-name">
                                                    {/* <Link to={`${ProductPageRootBase}${el.category_slug}/${el.slug}`}>{el.product_name}</Link> */}
                                                    {el.product_name}</td>
                                                <td className="txtc t-price">{globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽</td>
                                                <td className="t-quantity">
                                                    <ProductCounter onChange500={async (e) => await updateQuantity(e,el.product_id) } initialCount={el.quantity} />
                                                </td>
                                                <td className="txtc t-summ">{globalFunction.withSpaces(globalFunction.split(el.total_price)[0])} ₽</td>
                                                <td className="t-icons">
                                                    <div>
                                                        <button onClick={() => deleteProduct(el.product_id)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className="col" style={{border: '1px solid #D0D5DD', borderRadius: '5px'}}>
                                {Object.keys(productsAll)?.length > 0 && Object.values(productsAll)?.map((el) => (
                                    <div className="cart_mobile_product" key={el.product_id}>
                                        <div>
                                        {el.image_url ?
                                            <img style={{height: '75px', minWidth: '75px', borderRadius: '10px'}} src={`${egoda_url}${el.image_url}`} alt="img" />
                                            : <img style={{height: '75px', minWidth: '75px'}} src={`${img_pack.product_example}`} alt="img" />
                                        }
                                        </div>
                                        <div className="col-10">
                                            <div className="h-14-400-i">{el.product_name}</div>
                                            <div className="col-20" style={{padding: '10px'}}>
                                                <div className="h-12-500-i row-20-js">
                                                    <div>Цена</div>
                                                    <div>{globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽</div>
                                                </div>
                                                <div className="h-12-500-i row-20-js">
                                                    <div>Количество</div>
                                                    <div style={{width: 'max-content'}}>
                                                        <ProductCounter onChange500={async (e) => await updateQuantity(e,el.product_id) } initialCount={el.quantity} />
                                                    </div>
                                                </div>
                                                <div className="h-12-500-i row-20-js">
                                                    <div>Сумма</div>
                                                    <div>{globalFunction.withSpaces(globalFunction.split(el.total_price * el.quantity)[0])} ₽</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            )}
                            {windowWidth > 1275 &&
                            <div className="col-10">
                                <div className="cart-info-block">
                                    <div className="cart-info-block-img"><img src={`${img_pack.phone_call}`} alt="icon" /></div>
                                    <div className="cart-info-block-content">
                                        <div className="h-18-500-i gr8">Есть вопросы?</div>
                                        <div className="h-14-400-i gr8">Наши специалисты помогут, звоните +7 (951) 804-63-64</div>
                                    </div>
                                </div>
                                <div className="cart-info-block">
                                    <div className="cart-info-block-img"><img src={`${img_pack.shield}`} alt="icon" /></div>
                                    <div className="cart-info-block-content">
                                        <div className="h-18-500-i gr8">Безопасная покупка</div>
                                        <div className="h-14-400-i gr8">Все транзакции защищены SSL сертификатом</div>
                                    </div>
                                </div>
                                <div className="cart-info-block">
                                    <div className="cart-info-block-img"><img src={`${img_pack.eye_off}`} alt="icon" /></div>
                                    <div className="cart-info-block-content">
                                        <div className="h-18-500-i gr8">Защита данных</div>
                                        <div className="h-14-400-i gr8">Ваша конфиденциальность является главным приоритетом</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div>
                            <div className="cart-r-baseBlock">
                                <div className="h-16-700-i gr7">Сумма заказа</div>
                                <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                                {cart?.coupon ?
                                <div className="col-5">
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-700-i" style={{alignSelf: 'center'}}>Сумма без скидки</div>
                                        <div className="h-16-400-i">{globalFunction.withSpaces(globalFunction.split(+cart?.total_price)[0])} ₽</div>
                                    </div>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-700-i" style={{alignSelf: 'center'}}>Купон</div>
                                        <div className="h-16-400-i">{cart?.coupon}</div>
                                    </div>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-700-i" style={{alignSelf: 'center'}}>Тип скидки</div>
                                        <div className="h-16-400-i">{cart.discount_type === 'PERCENT' ? 'Процент скидки' : 'Сумма скидки'}</div>
                                    </div>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-700-i" style={{alignSelf: 'center'}}>Величина скидки</div>
                                        <div className="h-16-400-i">{cart.discount_type === 'PERCENT' ? `${cart?.discount} %` : `${cart?.discount} ₽`}</div>
                                    </div>
                                    <div style={{padding: '20px 0', gap: '20px', display: 'flex', justifyContent: 'space-between'}}>
                                        <div className="h-14-700-i" style={{alignSelf: 'center'}}>Итог:</div>
                                        <div className="h-30-400-i">{globalFunction.withSpaces(globalFunction.split(+cart?.total_price_with_discount)[0])} ₽</div>
                                    </div>
                                </div>
                                :
                                <div style={{padding: '20px 0', gap: '20px', display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="h-14-700-i" style={{alignSelf: 'center'}}>Итог:</div>
                                    <div className="h-30-400-i">{globalFunction.withSpaces(globalFunction.split(+cart?.total_price)[0])} ₽</div>
                                </div>
                                }

                                <button className="product_cart_btn h-14-600-i" disabled={Object.keys(productsAll)?.length > 0 ? false : true} onClick={() => navigate(`${CartOrderPageRoot}`)}>Оформить заказ</button>
                                {!couponOpen ?
                                <button className=" txtc gr5 h-12-500-i underline" onClick={() => setCouponOpen(true)}>У меня есть промокод</button>
                                :
                                <div className="cart-coupon-block">
                                    <div className="gr5 h-12-500-i">Промокод</div>
                                    <form className="cart-coupon-form" onSubmit={couponSubmit}>
                                        <div>
                                            {cart?.coupon ?
                                            <input name="coupon" type="text" defaultValue={cart.coupon || ''} className="base-input h-14-400-i" readOnly disabled/>
                                            :<input name="coupon" type="text" className="base-input h-14-400-i" placeholder="Введите промокод" />
                                            }
                                        </div>
                                        {cart?.coupon ?
                                        <button name="del" className="h-12-500-i gr8">Удалить</button>
                                        :<button name="aply" className="base-btn h-12-500-i white">Применить</button>
                                        }
                                    </form>
                                </div>}
                            </div>
                            <div className="cart-r-baseBlock">
                                <div className="h-16-700-i gr7">Оплата и доставка</div>
                                <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                                <div className="h-14-400-i">Оплатить товар можно с помощью: Банковские карты, QR-кода через СБП. Получить товар можно самостоятельно в пункте выдачи по адресу: г. Челябинск, Свердловский проспект, 62 или в СДЭК в случае региональной отправки по вашему адресу.</div>
                            </div>
                        </div>
                    </div>
                    {windowWidth <= 1275 &&
                        <div className="col-10">
                            <div className="cart-info-block">
                                <div className="cart-info-block-img"><img src={`${img_pack.phone_call}`} alt="icon" /></div>
                                <div className="cart-info-block-content">
                                    <div className="h-18-500-i gr8">Есть вопросы?</div>
                                    <div className="h-14-400-i gr8">Наши специалисты помогут, звоните +7 (351) 776-49-91</div>
                                </div>
                            </div>
                            <div className="cart-info-block">
                                <div className="cart-info-block-img"><img src={`${img_pack.shield}`} alt="icon" /></div>
                                <div className="cart-info-block-content">
                                    <div className="h-18-500-i gr8">Безопасная покупка</div>
                                    <div className="h-14-400-i gr8">Все транзакции защищены SSL сертификатом</div>
                                </div>
                            </div>
                            <div className="cart-info-block">
                                <div className="cart-info-block-img"><img src={`${img_pack.eye_off}`} alt="icon" /></div>
                                <div className="cart-info-block-content">
                                    <div className="h-18-500-i gr8">Защита данных</div>
                                    <div className="h-14-400-i gr8">Ваша конфиденциальность является главным приоритетом</div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </section>
       </>
    )
}

export default CartPage;