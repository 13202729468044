import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";
import { Link } from "react-router-dom";

const WhyPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Купить электронику с дисконтом 15-30% от рыночных цен? Такое возможно только с магазином электроники и бытовой техники "PROstore&PROtechno"💻📱🎮⌚️!!!</div>
                            <div className="h-14-400-i c-2">Основной акцент в работе нашей компании сделан на доставку товаров с Объединенных Арабских Эмиратов под заказ с ожиданием порядка 27-29 рабочих дней.</div>
                            <div className="h-14-400-i c-2">Как и в любой другой компании, мы также продаём товар из наличия, но по стоимости ближе к рыночным ценам ввиду того, что не демпингуем рынок, так, как придерживаемся мнения относительного того, что на рынке должна быть здоровая конкуренция. 🙌</div>
                        </div>
                        <div className="col-20">
                            <div className="h-14-400-i c-2">В карточке товара Вы можете наблюдать две цены: "купить под заказ" и "купить из наличия":</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>При выборе варианта "купить под заказ" по выгодной цене, система "перекидывает" на заполнение формы обратной связи для подачи заявки на приобретение техники под заказ, в этом случае, после заполнения формы, с Вами выходит на связь наш менеджер и консультрует Вас по условиям работы с нами и после консультации Вы сможете приобрести товар по этой "сладкой" цене. 🤝</li>
                                <li>При выборе варианта "купить из наличия", выбранный Вами товар попадает в корзину, где оформляется заказ с возможностью оплатить его онлайн с помощью дебетовой/кредитной карты либо QR-кода по СБП (система быстрых платежей). Все платежи осуществляются через защищенный платежный шлюз, который обеспечивает безопасность транзакций, поэтому Вы можете всегда быть уверены в своей покупке.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-14-400-i c-2">На весь ассортимент представленного товара, мы даём гарантию 12 месяцев с даты передачи клиенту товара "в руки".🥇</div>
                            <div className="h-14-400-i c-2">Вы всегда можете задать все интересующие вопросы по работе с нами по следующим каналам:</div>
                            <div className="h-14-400-i c-2">Написав нам на почту: <a href="mailto:manager@prostore-protechno.ru">manager@prostore-protechno.ru</a></div>
                            <div className="h-14-400-i c-2">Написав нам в WhatsApp: <Link to={'https://wa.me/79518046364'} target="_blank">https://wa.me/79518046364</Link></div>
                            <div className="h-14-400-i c-2">Позвонив по номеру телефона: <a href="tel:+79518046364">+7 (951) 804-63-64</a></div>
                            <div className="h-14-400-i c-2">Заказав звонок в шапке сайта 👆</div>
                            <div className="h-14-400-i c-2">Мы будем всегда ждать Вас у нас в магазине, дорогой клиент!</div>
                            <div className="h-14-400-i c-2">С уважением, магазин электроники и бытовой техники "PROstore&PROtechno"💻📱🎮⌚️</div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default WhyPage;