import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const PriceRangeSlider = ({rangePrice, setRangePrice, minPrice, maxPrice}) => {

  return (
    <>
      <div className='row-20-a' style={{display: 'flex'}}>
        <input className='base-input' type="number" 
        placeholder='от 0'
        value={rangePrice[0] || ''} 
        onChange={(e) => {
          const newValue = Math.max(0, Math.min(maxPrice, e.target.value));
          setRangePrice([newValue, rangePrice[1]]);
        }}
        />
        <input className='base-input' type="number" 
        placeholder={`до ${maxPrice}`}
        value={rangePrice[1] || ""} 
        onChange={(e) => {
          const newValue = Math.max(0, Math.min(maxPrice, e.target.value));
          setRangePrice([rangePrice[0], newValue]);
        }}/>
      </div>
      <div style={{ width: '100%', margin: '0 auto', position: 'relative' }}>
        <Slider
          range
          min={0}
          max={maxPrice}
          value={rangePrice}
          onChange={(value) => setRangePrice(value)}
        />
      </div>
    </>
  );
};

export default PriceRangeSlider;
