import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import '@splidejs/splide/css';
import "react-datepicker/dist/react-datepicker.css"
import 'react-toastify/dist/ReactToastify.css';
import './css/style.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App />
  </Router>
);
