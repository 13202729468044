import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const ExchangeAndRefundPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Условия возврата товаров надлежащего качества</div>
                            <div className="h-14-400-i c-2">Возврат товара надлежащего качества возможен в течение 14 дней, не считая дня получения. Для возврата необходимо сохранить товарный вид, потребительские свойства, а также документ, подтверждающий покупку. Возврат средств Покупателю производится в течение 10 дней с момента получения товара и заявления на возврат.</div>
                            <div className="h-14-400-i c-2">Местом получения возвращаемого товара является пункт выдачи: г. Челябинск, Свердловский проспект, 62. Возврат осуществляется тем же способом оплаты, которым был оплачен заказ. Положения о возврате товаров надлежащего качества регулируются статьями 18-24 Закона «О защите прав потребителей».</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Условия возврата товаров ненадлежащего качества</div>
                            <div className="h-14-400-i c-2">Гарантийные случаи рассматриваются в соответствии с Законом «О защите прав потребителей». Признание товарного брака после эксплуатации шин и дисков осуществляется на основании независимой экспертизы, в сроки, установленные ст. 18, 20-22 Закона РФ «О защите прав потребителей» (от 07.02.1992 N 2300-1), за исключением случаев очевидного визуального брака.</div>
                            <div className="h-14-400-i c-2">Если дефект признан заводским браком, возврат средств Покупателю производится в течение 10 дней с момента получения решения по экспертизе и заявления на возврат. Возврат осуществляется тем же способом оплаты, которым был оплачен заказ. В случае обмена товара с заводским дефектом все расходы на доставку и транспортировку покрываются компанией.</div>
                            <div className="h-14-400-i c-2">Политика возврата распространяется на все товары, представленные в каталоге. Для инициации возврата/обмена товара свяжитесь с нами по телефону +7 (951) 804-63-64 или отправьте письмо на manager@prostore-protechno.ru.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default ExchangeAndRefundPage;