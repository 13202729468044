import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const RequisitesPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-14-400-i c-2">Индивидуальный предприниматель Стрельников Максим Алексеевич</div>
                            <div className="h-14-400-i c-2">Адрес: 454080, г. Челябинск, Свердловский проспект, 62</div>
                            <div className="h-14-400-i c-2">ИНН: 751505636707</div>
                            <div className="h-14-400-i c-2">ОГРНИП: 324745600015950 от 30.01.2024</div>
                            <div className="h-14-400-i c-2">Банк: ООО "Банк Точка"</div>
                            <div className="h-14-400-i c-2">р/с 40802810320000393053</div>
                            <div className="h-14-400-i c-2">к/с 30101810745374525104</div>
                            <div className="h-14-400-i c-2">БИК 044525104</div>
                            <div className="h-14-400-i c-2">manager@prostore-protechno.ru</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default RequisitesPage;