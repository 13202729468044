import React, { useEffect, useRef, useState } from "react"
import img_pack from "../../img/img"
import ToggleBtn from "./toggleBtn"
import { TagsInput } from 'react-tag-input-component';

const MainSettingsTabs = ({settingsTabs, setSettingsTabs}) => {

    const [selectedBtn, setSelectedBtn] = useState('1')

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1" style={{width: 'max-content'}}>
                <button style={{minWidth: '200px'}} type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick}>
                    <img_pack.settings_icon />
                    <div className="h-14-500-i">Основные</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Email системного администратора</td>
                            <td style={{width: '500px'}}>
                                <TagsInput
                                    value={settingsTabs?.admin_emails || []}
                                    onChange={(e) => setSettingsTabs({...settingsTabs, admin_emails: e })}
                                    name="email"
                                    placeHolder="Введите email"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

export default MainSettingsTabs;