import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import { AdminStoreBrandsRedRootBase, AdminStoreBrandsRoot, egoda_url } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";
import EditorComponent from "./components/EditorCompoenent";
import ToggleBtn from "./components/toggleBtn";

const AdminStoreBrandsRedPage = () => {

    // Первичные запросы

    const [editorPreviewShortDesc, setEditorPreviewShortDesc] = useState('')
    const [editorContentShortDesc, setEditorContentShortDesc] = useState({})
    const [editorPreviewFullDesc, setEditorPreviewFullDesc] = useState('')
    const [editorContentFullDesc, setEditorContentFullDesc] = useState({})
    const [isPopular, setIsPopular] = useState(false);
    
    const [logo, setLogo] = useState('')
    const [img, setImg] = useState('')

    const [slug, setSlug] = useState('')
    const [brandsDetail, setBrandsDetail] = useState([])
    const [catsAll, setCatsAll] = useState([])
    const [catsAddSelected, setCatsAddSelected] = useState([])
    
    const [itemPodata, setItemPodata] = useState({title: 'Бренд будет удален', desc: 'Вы уверены, что хотите удалить бренд безвозвратно?'});
    const {slugParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminStoreBrandsRedRootBase)
    const navigate = useNavigate()

    const [GetBrandCatsAll] = useFetching( async () => {
        const res = await GetStore.GetBrandCatsAll()
        setCatsAll(res.data.data)
        // console.log('GetBrandCatsAll complete:' , res.data)
    })
    
    const [GetStoreBrand] = useFetching( async (slug) => {
        const res = await GetStore.GetStoreBrand(slug)
        setBrandsDetail(res.data)
        // console.log('GetStoreBrand complete:' , res.data)
        if (res.data) {
            const form = document.querySelector('.new_category_form')
            form.elements.brand_name.value = res.data?.name
            form.elements.brand_slug.value = res.data?.slug
            res.data.category_slug && setCatsAddSelected([{value: res.data.category_slug, name: res.data.category_name}])
            setIsPopular(res.data?.is_popular)
            setLogo(res.data?.image_logo)
            setImg(res.data?.image)
            res.data?.short_desc && setEditorPreviewShortDesc(res.data.short_desc)
            res.data?.desc && setEditorPreviewFullDesc(res.data.desc)
        }
    })
        
    useEffect(() => {
        if(isEdit && slugParam) {
            GetStoreBrand(slugParam)
        }
        GetBrandCatsAll()
    },[])

    // Добавление\изменение новой категории

    const [AddNewStoreBrands] = useFetching( async (data) => {
        const res = await GetStore.AddNewStoreBrands(data)
        // console.log('AddNewStoreBrands complete:' , res.data)
        navigate(`${AdminStoreBrandsRoot}`)
        return res;
    })
    
    const [UpdateBrands] = useFetching( async (data) => {
        const res = await GetStore.UpdateBrands(data, brandsDetail?.slug)
        // console.log('UpdateBrands complete:' , res.data)
        navigate(`${AdminStoreBrandsRoot}`)
        return res;
    })

    function submit(e) {
        e.preventDefault()
        const name = e.target.elements.brand_name.value
        const curSlug = slugify(e.target.elements.brand_slug.value, { lower: true })

        const data = new FormData();
        data.append("name", name);
        data.append("slug", curSlug);
        data.append("short_desc", JSON.stringify(editorContentShortDesc) || '');
        data.append("desc", JSON.stringify(editorContentFullDesc) || '');
        data.append("image", img?.id || '');
        data.append("image_logo", logo?.id || '');
        data.append("category", catsAddSelected.length > 0 ? catsAddSelected[0]?.value : '');
        data.append("is_popular", isPopular);

        if(isEdit) {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                UpdateBrands(data)
            }
        } else {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                AddNewStoreBrands(data)
            }
        }
        
    }

    function slugifyTitle(e) {
        const newTitle = e.target.value;
        const newSlug = slugify(newTitle, { lower: true });
        setSlug(newSlug)
    }

    // Удаление бренда
    const [DeleteStoreBrand] = useFetching( async (slug) => {
        const res = await GetStore.DeleteStoreBrand(slug)
        // console.log('DeleteStoreBrand complete:' , res.data)
        navigate(`${AdminStoreBrandsRoot}`)
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteStoreBrand(brandsDetail?.slug)
        }
    }, [location.state])

    // // Получение файла с попапа медиафайлов

    useEffect(() => {
        if (location.state?.confirmFile) {
            // Логика на confirmFile
            // console.log(location.state.confirmFile)
            if(location.state.confirmFile.holder === '1') {
                setLogo(location.state.confirmFile)
            } else {
                setImg(location.state.confirmFile)
            }
            // Обнуление состояния confirm в истории
            location.state.confirmFile = undefined
            window.history.replaceState({}, '')
        }
    }, [location.state])
    

    return (
        <div className="admin_content_base" style={{maxWidth: '700px'}}>
            <form onSubmit={submit} className="new_category_form">
                <span className="h-18-500-i gr7">{isEdit ? 'Изменить бренд' : 'Добавить новый бренд'}</span>
                <div className="col-5">
                    <span className="h-12-600-i gr7">Название нового бренда</span>
                    <input onChange={slugifyTitle} name="brand_name" className="base-input h-12-400-i gr7" placeholder="Название" type="text" required/>
                </div>
                <div className="col-5">
                    <span className="h-12-600-i gr7">Slug бренда</span>
                    <input name="brand_slug" defaultValue={slug} className="base-input h-12-400-i gr7" placeholder="Slug" type="text" required/>
                </div>
                <div className="col-5">
                    <span className="h-12-600-i gr7">Популярный бренд</span>
                    <ToggleBtn toggleActive={isPopular} onChange={(e) => setIsPopular(e)} />
                </div>
                
                <div className="col-5">
                    <span className="h-12-600-i gr7">Категория бренда</span>
                    <CustomSelect allOption={catsAll} ActiveItems={catsAddSelected} setActiveItems={setCatsAddSelected} single={true} enclosure={false} placeholder={'Выбрать категорию'}/>
                </div>
                <div className="col-5">
                    <span className="h-14-500-i c-3">Логотип бренда</span>
                    {logo?.file ?
                    <>
                    <div className="preview_img">
                        <img src={(logo.file instanceof Blob || logo.file instanceof File) ? URL.createObjectURL(logo.file) : `${egoda_url}${logo.file}`} alt="img"/>
                    </div>
                    <button onClick={() => {setLogo('')}} type="button" className="h-12-400-i txtc">Удалить изображение</button>
                    </>
                    :
                    <div className="mediafilesPo_input">
                        <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {isMulti: false, holder: '1'} } })}></button>
                    </div>
                    }
                </div>
                <div className="col-5">
                    <span className="h-14-500-i c-3">Изображение бренда</span>
                    {img?.file ?
                    <>
                    <div className="preview_img">
                        <img src={(img.file instanceof Blob || img.file instanceof File) ? URL.createObjectURL(img.file) : `${egoda_url}${img.file}`} alt="img"/>
                    </div>
                    <button onClick={() => {setImg('')}} type="button" className="h-12-400-i">Удалить изображение</button>
                    </>
                    :
                    <div className="mediafilesPo_input">
                        <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {isMulti: false, holder: '2'} } })}></button>
                    </div>
                    }
                </div>
                <div className="col-5">
                    <div className="h-12-600-i gr7">Краткое описание</div>
                    <div className="editorJs_container editor_min" style={{border: '1px solid #d0d5dd', borderRadius: '8px', padding: '15px'}}>
                        <EditorComponent editorPreview={editorPreviewShortDesc} setEditorContent={setEditorContentShortDesc} holder={'editorjs_1'}/>
                    </div>
                </div>
                <div className="col-5">
                    <div className="h-12-600-i gr7">Полное описание</div>
                    <div className="editorJs_container editor_min" style={{border: '1px solid #d0d5dd', borderRadius: '8px', padding: '15px'}}>
                        <EditorComponent editorPreview={editorPreviewFullDesc} setEditorContent={setEditorContentFullDesc} holder={'editorjs_2'}/>
                    </div>
                </div>
                <div className="row-20-js">
                    <button className="admin_save_btn" type="submit">
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{isEdit ? 'Изменить' : 'Добавить'}</span>
                    </button>
                    {isEdit &&
                    <Link to={'/item_po'} state={{prevLocation: location, itemPodata: itemPodata}} className="admin_delete_btn">
                       <img src={img_pack.trash_icon} alt="trash_icon" />
                       <span className="h-12-600-i white">Удалить</span>
                    </Link>
                    }
                </div>
            </form>
        </div>
    )
}

export default AdminStoreBrandsRedPage;