import React, { useEffect, useRef, useState } from "react"

const Product_tabs = ({product}) => {

    const [selectedBtn, setSelectedBtn] = useState('')

    useEffect(() => {
        if(product) {
            if(product.desc?.blocks?.length > 0 || product?.product_atr?.length > 0) {
                if(product.desc?.blocks?.length > 0) {
                    setSelectedBtn('desc')
                } else {
                    setSelectedBtn('prop')
                }
            } else {
                setSelectedBtn(product.product_tab[0]?.id)
            }
        }
    },[product])
    
    return (
        <>
        {
        <div className="product_tabs">
            {product.desc?.blocks?.length > 0 &&
                <button className={`h-14-600-i ${selectedBtn === 'desc' ? 'active' : ''}`} key={'desc'} onClick={() => setSelectedBtn('desc')}>Описание</button>
            }
            {product?.product_atr?.length > 0 &&
                <button className={`h-14-600-i ${selectedBtn === 'prop' ? 'active' : ''}`} key={'prop'} onClick={() => setSelectedBtn('prop')}>Характеристики</button>
            }
            {product?.product_tab?.map((el) => (
                <button className={`h-14-600-i ${selectedBtn === el.id ? 'active' : ''}`} key={el.id} onClick={() => setSelectedBtn(el.id)}>{el.name}</button>
            ))}
        </div>}
        {(selectedBtn !== '' && selectedBtn !== undefined) &&
            (selectedBtn === 'desc' ?
                <div className="product-info-block">

                    <div className="col-15">
                        <div className="h-20-400-i">Описание</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="col-20 h-14-400-i">
                        {product.desc?.blocks?.map((el) => (
                            el.type === 'header' ? React.createElement(`h${el.data.level}`, { key: el.id, id: el.id, className: "h-21-600-sf", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : el.type === 'paragraph' ? React.createElement(`p`, { key: el.id, className: "h-16-400-sf c-6", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : <span key={el.id} className="h-16-400-sf c-6" dangerouslySetInnerHTML= {{ __html: (el.data.text || el.data.code) }}></span>
                        ))}
                    </div>

                </div>
                : (selectedBtn === 'prop' ?
                <div className="product-info-block">
                    <div className="col-15">
                        <div className="h-20-400-i">Характеристики</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="product-info-row">
                        {product?.product_atr?.map((el) => (
                        <div key={el.id} className="product-detail-ex">
                            <div className="h-14-700-i">{el.name}:</div>
                            <div className="h-14-400-i">{el.value}</div>
                        </div>
                        ))
                        }
                    </div>
                </div>
                :
                <div className="product-info-block">
                    <div className="col-15">
                        <div className="h-20-400-i">{product.product_tab?.find(obj => obj.id === selectedBtn)?.name}</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="h-14-400-i">
                        {product.product_tab?.find(obj => obj.id === selectedBtn)?.value?.blocks?.map((el) => (
                            el.type === 'header' ? React.createElement(`h${el.data.level}`, { key: el.id, id: el.id, className: "h-21-600-sf", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : el.type === 'paragraph' ? React.createElement(`p`, { key: el.id, className: "h-16-400-sf c-6", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : <span key={el.id} className="h-16-400-sf c-6" dangerouslySetInnerHTML= {{ __html: (el.data.text || el.data.code) }}></span>
                        ))}
                    </div>
    
                </div>)
            )
        }
        </>
    )
}

export default Product_tabs;