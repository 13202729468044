import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const FaqPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Могу ли я оплатить заказ частично?</div>
                            <div className="h-14-400-i c-2">Нет, для завершения покупки требуется полная оплата заказа.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Что делать, если оплата не прошла?</div>
                            <div className="h-14-400-i c-2">Попробуйте еще раз или свяжитесь с нашим отделом поддержки клиентов для получения помощи.<br/>
                            email: <a href="mailto:manager@prostore-protechno.ru">manager@prostore-protechno.ru</a><br/>
                            Телефоны для связи: <a href="tel:+79518046364">+7 (951) 804-63-64</a>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Безопасно ли оплачивать на сайте?</div>
                            <div className="h-14-400-i c-2">Да, все транзакции защищены современными методами шифрования.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default FaqPage;