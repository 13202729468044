import React, { useEffect, useRef, useState } from "react"
import img_pack from "../../img/img"
import ToggleBtn from "./toggleBtn"
import EditorComponent from "./EditorCompoenent"
import { AdminStoreRedRootBase } from "../../utils/consts"
import { useLocation } from "react-router"

const ProductRedTabs = ({productRedTabsData, setProductRedTabsData}) => {

    const [selectedBtn, setSelectedBtn] = useState('1')
    // '3'
    const [productAtr, setProductAtr] = useState([])
    // '4'
    const [editorPreviewTab, setEditorPreviewTab] = useState('')
    const [editorContentTab, setEditorContentTab] = useState({})
    const [productTabs, setProductTabs] = useState(productRedTabsData.productAtr || [])

    const [tabCurrentIndex, setTabCurrentIndex] = useState(null)
    const [initializedIndex, setInitializedIndex] = useState(null)

    const [productAtrInit, setProductAtrInit] = useState(false)
    const [productTabInit, setProductTabInit] = useState(false)

    const location = useLocation()
    const isEdit = location.pathname.includes(AdminStoreRedRootBase)

    useEffect(() => {
        // console.log('productRedTabsData', productRedTabsData)
        if(isEdit) {
            if(productRedTabsData?.productAtr.length > 0 && !productAtrInit) {
                setProductAtrInit(true)
                setProductAtr(productRedTabsData.productAtr)
            }
            if(productRedTabsData?.productTabs.length > 0 && !productTabInit) {
                setProductTabInit(true)
                setProductTabs(productRedTabsData.productTabs)
            }
        }
    },[productRedTabsData])

    // Функция для обработки логики первой инициализации
    const handleEditorInitialized = (tabIndex) => {
        setInitializedIndex(tabIndex)
    };
    useEffect(() => {
        setEditorPreviewTab('')
        if(initializedIndex === tabCurrentIndex && initializedIndex !== null && productTabs[tabCurrentIndex]?.value) {
            setEditorPreviewTab(productTabs[tabCurrentIndex]?.value)
            setInitializedIndex(null)
        }
    },[initializedIndex])

    //  Добавление эдитора в productTabs
    useEffect(() => {
        if(Object.keys(editorContentTab).length !== 0) {
            setProductTabs((prevProductTabs) => {
                const updatedTabs = [...prevProductTabs];
                updatedTabs[tabCurrentIndex] = {...updatedTabs[tabCurrentIndex], value: editorContentTab}
                return updatedTabs;
            })
        }
    },[editorContentTab])

    // Добавление\удаление Вкладки
    useEffect(() => {
        if (productTabs.length !== 0) {
            setProductRedTabsData({...productRedTabsData, productTabs: productTabs})
        }
    },[productTabs])
    function addProductTab() {
        if(productTabs.length === 0) {
            setProductTabs([...productTabs, {id: 1, name: 'Название', value: '', order: null}])
            setTabCurrentIndex(0)
        } else {
            let maxId = Math.max(...productTabs.map(item => item.id));
            let newId = maxId + 1;
            setProductTabs([...productTabs, {id: newId, name: 'Название', value: '', order: null}])
            setTabCurrentIndex(productTabs.length)
        }
    }

    function delProductTab() {
        if (tabCurrentIndex !== null) {
            productTabs.splice(tabCurrentIndex, 1);
            setTabCurrentIndex(null)
            setProductTabs([...productTabs])
        } 
    }

    // Добавление\удаление Аттрибута
    useEffect(() => {
        if (productAtr.length !== 0) {
            setProductRedTabsData({...productRedTabsData, productAtr: productAtr})
        }
    },[productAtr])
    function addProductAtr() {
        if(productAtr.length === 0) {
            setProductAtr([...productAtr, {id: 1, name: '', value: '', order: ''}])
        } else {
            let maxId = Math.max(...productAtr.map(item => item.id));
            let newId = maxId + 1;
            setProductAtr([...productAtr, {id: newId, name: '', value: '', order: ''}])
        }
    }
    function delProductAtr(id) {
        let indexToRemove = productAtr.findIndex(item => item.id === id);
        if (indexToRemove !== -1) {
            productAtr.splice(indexToRemove, 1);
            setProductAtr([...productAtr])
        } 
    }

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1">
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick}>
                    <img_pack.dollar_icon />
                    <div className="h-14-500-i">Основные</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '2' ? 'active' : ''}`} numb={2} onClick={menuBtnClick}>
                    <img_pack.package_icon />
                    <div className="h-14-500-i">Запасы</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '3' ? 'active' : ''}`} numb={3} onClick={menuBtnClick}>
                    <img_pack.tag_icon />
                    <div className="h-14-500-i">Атрибуты</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '4' ? 'active' : ''}`} numb={4} onClick={menuBtnClick}>
                    <img_pack.tabs_icon />
                    <div className="h-14-500-i">Вкладки</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Цена под заказ (₽)</td>
                            <td><input name="price" type="text" className="base-input" value={productRedTabsData.price_order || ''} onChange={(e) => setProductRedTabsData({...productRedTabsData, price_order: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Цена из наличия (₽)</td>
                            <td><input name="price" type="text" className="base-input" value={productRedTabsData.price_now || ''} onChange={(e) => setProductRedTabsData({...productRedTabsData, price_now: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Включить акционную цену</td>
                            <td><ToggleBtn toggleActive={productRedTabsData?.promoPriceToggle} onChange={(e) => setProductRedTabsData({...productRedTabsData, promoPriceToggle: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Популярный товар</td>
                            <td><ToggleBtn toggleActive={productRedTabsData?.is_popular} onChange={(e) => setProductRedTabsData({...productRedTabsData, is_popular: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Цена по акции (₽)</td>
                            <td><input name="promoPrice" type="text" className="base-input" value={productRedTabsData.promoPrice || ''} onChange={(e) => setProductRedTabsData({...productRedTabsData, promoPrice: e.target.value})}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
            {selectedBtn === '2' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>    
                            <td className="h-16-400-i">Артикул</td>
                            <td><input name="article" type="text" className="base-input" value={productRedTabsData.article || ''} onChange={(e) => setProductRedTabsData({...productRedTabsData, article: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Управление запасами</td>
                            <td><ToggleBtn toggleActive={productRedTabsData?.invManagToggle} onChange={(e) => setProductRedTabsData({...productRedTabsData, invManagToggle: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Не показывать товар если его нет в наличии</td>
                            <td><ToggleBtn toggleActive={productRedTabsData?.dontShowToggle} onChange={(e) => setProductRedTabsData({...productRedTabsData, dontShowToggle: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Количество товара</td>
                            <td><input name="quantityProducts" type="text" className="base-input" value={productRedTabsData.quantityProducts || ''} onChange={(e) => setProductRedTabsData({...productRedTabsData, quantityProducts: e.target.value})}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
            {selectedBtn === '3' &&
            <div className="productRed_content base_content_1">
                <table className="attr_table">
                    <thead>
                        <tr>
                            <th><div className="h-16-400-i">Наименование</div></th>
                            <th><div className="h-16-400-i">Значение</div></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody> 
                        {productAtr?.map((el,i) => (
                            <tr key={el.id}>
                                <td>
                                    <input name="t_3_name" type="text" className="base-input" 
                                    value={el.name || ''} 
                                    onChange={(e) => {
                                        setProductAtr((prevProductAtr) => {
                                        const updatedAtr = [...prevProductAtr];
                                        updatedAtr[i] = {...updatedAtr[i], name: e.target.value}
                                        return updatedAtr;
                                    })}}/>
                                </td>
                                <td>
                                    <input name="t_3_value " type="text" className="base-input" 
                                    value={el.value || ''} 
                                    onChange={(e) => {
                                        setProductAtr((prevProductAtr) => {
                                        const updatedAtr = [...prevProductAtr];
                                        updatedAtr[i] = {...updatedAtr[i], value: e.target.value}
                                        return updatedAtr;
                                    })}}/>
                                </td>
                                <td><button type="button" onClick={() => delProductAtr(el.id)}><img src={img_pack.articles_trash_icon} alt="img" /></button></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
                <div className="t_add_btn" style={{margin: '10px 24px 0 24px'}}>
                    <button type="button" onClick={addProductAtr}></button>
                </div>
            </div>
            }
            {selectedBtn === '4' &&
            <>
            <div className="productRed_content_tabs_row base_content_1">
                {productTabs.map((el,i) => (
                    <button key={el.id} type="button" className={`productRed_tab_btn ${tabCurrentIndex === i ? 'active' : ''} `} onClick={() => setTabCurrentIndex(i)}>
                        <div className="h-14-500-i">{el.name}</div>
                    </button>
                ))}
                <div className="t_add_btn" style={{maxWidth: '150px', width: '100%'}}>
                    <button type="button" onClick={addProductTab}></button>
                </div>
            </div>
            {(tabCurrentIndex !== null && tabCurrentIndex >= 0 && tabCurrentIndex < productTabs.length) &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>    
                            <td className="h-16-400-i">Наименование</td>
                            <td>
                                <input name="t_4_name" type="text" className="base-input" 
                                    value={productTabs[tabCurrentIndex]?.name || ''} 
                                    onChange={(e) => {
                                        setProductTabs((prevProductTabs) => {
                                        const updatedTabs = [...prevProductTabs];
                                        updatedTabs[tabCurrentIndex] = {...updatedTabs[tabCurrentIndex], name: e.target.value}
                                        return updatedTabs;
                                    })}} 
                                />
                            </td>
                        </tr>
                        <tr>    
                            <td className="h-16-400-i">Порядок</td>
                            <td>
                                <input name="t_4_order" type="text" className="base-input" 
                                    value={productTabs[tabCurrentIndex]?.order || ''} 
                                    onChange={(e) => {
                                        setProductTabs((prevProductTabs) => {
                                        const updatedTabs = [...prevProductTabs];
                                        updatedTabs[tabCurrentIndex] = {...updatedTabs[tabCurrentIndex], order: e.target.value}
                                        return updatedTabs;
                                    })}} 
                                />
                            </td>
                        </tr>
                        <tr>    
                            <td className="h-16-400-i">Удалить?</td>
                            <td><button type="button" onClick={delProductTab}><img src={img_pack.articles_trash_icon} alt="img" /></button></td>
                        </tr>
                    </tbody>
                </table>
                <div className="t_editor_block">
                    <div className="h-16-400-i">Содержание вкладки</div>
                    <div className="editorJs_container editor_min">
                        <EditorComponent key={tabCurrentIndex} onInitialized={() => handleEditorInitialized(tabCurrentIndex)} editorPreview={editorPreviewTab} setEditorContent={setEditorContentTab} holder={`editorjs_tab`}/>
                    </div>
                </div>
            </div>
            }
            </>
            }
        </div>
    )
}

export default ProductRedTabs;