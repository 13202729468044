import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "./components/Pagination";
import img_pack from "../img/img";
import { AdminStoreImportRoot, AdminStoreNewRoot, AdminStoreRedRootBase, ProductPageRootBase, egoda_url } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";

const AdminStorePage = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalProducts, setTotalProducts] = useState(null)
    const [itemPodata, setItemPodata] = useState({title: 'Товар будет удален', desc: 'Вы уверены, что хотите удалить товар безвозвратно?'});
    const [curSlug, setCurSlug] = useState('');
    const [query, setQuery] = useState('');

    const [productsAll, setProductsAll] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    
    // checbox click
    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }

    const downloadFile = (filePath) => {
        const a = document.createElement('a');
        a.href = `${egoda_url}${filePath}`; // Указываем путь к файлу
        a.download = ''; // Пустая строка подразумевает, что имя файла будет взято из URL
        document.body.appendChild(a);
        a.click(); // Инициируем клик
        a.remove(); // Удаляем элемент из DOM
      };
    
    const [Export] = useFetching( async () => {
        const res = await GetStore.Export('')
        // console.log('Export complete:' , res.data)
        if(res.data.url_file) {
            downloadFile(res.data.url_file)
        }
    })

    // Получение всех товаров
    const [ProductsAll] = useFetching( async (page) => {
        const formdata = new FormData();
        formdata.append("query", query || ''); //поиск по тексту
        const res = await GetStore.ProductsAll(page, formdata)
        // console.log('ProductsAll complete:' , res.data)
        setProductsAll(res.data?.result)
        setTotalPages(res.data?.total_pages)
        setTotalProducts(res.data?.total)
    })
    

    const prevQueryRef = useRef(query);
    useEffect(() => {
        if (prevQueryRef.current !== query) {
            if(currentPage !== 1) {
                setCurrentPage(1)
            } else {
                ProductsAll(1)
            }
            prevQueryRef.current = query;
        }
    }, [query]);
    useEffect(() => {
        ProductsAll(currentPage)
    }, [currentPage]);

    // Удаление поста
    function deleteProduct(slug) {
        setCurSlug(slug)
        navigate('/item_po', { state: { prevLocation: location, itemPodata: itemPodata } })
    }

    const [DeleteProduct] = useFetching( async (slug) => {
        const res = await GetStore.DeleteProduct(slug)
        // console.log('DeleteProduct complete:' , res.data)
        if(currentPage !== 1) {
            setCurrentPage(1)
        } else {
            ProductsAll(currentPage)
        }
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteProduct(curSlug)
            setCurSlug('')
        }
    }, [location.state])

    function submit(e) {
        e.preventDefault()
        setQuery(e.currentTarget.elements.search.value)
        // console.log(e.currentTarget.elements.search.value)
    }

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Товары</div>
                    <span className="h-12-500-i ba-or">{totalProducts} шт</span>
                </div>
                <div className="row-10-a">
                    <form className={`header-search-form`} onSubmit={submit} style={{display: 'flex', gap: '5px'}}>
                        <input className="base-input" name="search" type="text" placeholder="Найти"/>
                        <button type="submit"><img src={img_pack.search_icon} alt="ic" /></button>
                    </form>
                    <button onClick={() => Export()} className="create_plus_btn" style={{minWidth: 'max-content'}}>
                        <img src={img_pack.upload_icon} alt="ic" />
                        <span className="h-14-600-i gr7">Экспорт</span>
                    </button>
                    <Link to={`${AdminStoreImportRoot}`} className="create_plus_btn" style={{minWidth: 'max-content'}}>
                        <img src={img_pack.download_icon} alt="ic"/>
                        <span className="h-14-600-i gr7">Импорт</span>
                    </Link>
                    <Link to={`${AdminStoreNewRoot}`} className="create_plus_btn" style={{minWidth: 'max-content'}}>
                        <img src={img_pack.plus_icon} alt="plus_icon" />
                        <span className="h-14-600-i gr7">Создать</span>
                    </Link>
                </div>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Название
                            </div>
                        </th>
                        <th>
                            <div>
                                Статус
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th>
                            <div>Цена</div>
                        </th>
                        <th>
                            <div>Дата</div>
                        </th>
                        <th>
                            <div>Категория</div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {productsAll?.map((el) => (
                        <tr key={el.id}>
                            <td className="t-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <Link to={`${AdminStoreRedRootBase}${el.slug}`} style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                                        {el.image_url 
                                        ?<img src={`${egoda_url}${el.image_url}`} alt="product_img" /> 
                                        :<img src={`${img_pack.product_example}`} alt="img" />}
                                        <span className="h-14-500-i gr7">{el.name}</span>
                                    </Link>
                                </div>
                            </td>
                            <td className="t-status">
                                {el.status === 'PB' ?
                                <div className="ba-pub">
                                    <div />
                                    <span className="h-12-500-i">Опубликовано</span>
                                </div>
                                : 
                                <div className="ba-dr">
                                    <div />
                                    <span className="h-12-500-i">Черновик</span>
                                </div>
                                }
                            </td>
                            <td className="t-price">
                                <div>
                                    <span className="h-14-400-i gr7">{el.price_order} ₽</span>
                                </div>
                            </td>
                            <td className="t-date">
                                <div>
                                    <span className="h-14-400-i gr7">{el.created ? format(new Date(el.created), "dd.MM.yyyy") : '-'}</span>
                                </div>
                            </td>
                            <td className="t-cats">
                                <div>
                                    <div key={el.id} className="h-12-500-i ba">{el.category_name}</div>

                                    {/* <button className="h-12-500-i gr5 ba_more_btn">+4</button> */}
                                </div>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <Link to={`${ProductPageRootBase}${el.category_slug}/${el.slug}`}><img src={img_pack.articles_eye_icon} alt="eye_icon" /></Link>
                                    <button onClick={(e) => deleteProduct(el.slug)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                    <Link to={`${AdminStoreRedRootBase}${el.slug}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => {setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i gr7">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => {setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="h-14-600-i gr7">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminStorePage;