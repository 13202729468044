import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetCart {
    // Показать корзину
    static async ShowCart(data) {
        const response = await axios.post(`${egoda_url}/cart/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Добавить товар в корзину
    static async AddCart(data) {
        const response = await axios.post(`${egoda_url}/cart/add/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Обновить корзину
    static async UpdateCart(data) {
        const response = await axios.post(`${egoda_url}/cart/update/`, data,
        {
            'Content-Type': 'application/json',
            withCredentials: true
        });

        return response;
    }
    // Удалить из корзины
    static async DeleteFromCart(data) {
        const response = await axios.post(`${egoda_url}/cart/remove/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Применить купон
    static async ApplyCoupon(data) {
        const response = await axios.post(`${egoda_url}/cart/coupon/apply/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Отменить купон
    static async RemoveCoupon(data) {
        const response = await axios.post(`${egoda_url}/cart/coupon/remove/`, data,
        {
            withCredentials: true
        });

        return response;
    }
}