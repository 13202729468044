import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import { Context } from "../App";
import { useLocation, useNavigate } from "react-router";
import { AdminMediafilesRoot, Email_verify_Root, Login_Root, Password_new_Root, Password_recovery_Root, Registration_Root } from "../utils/consts";
import { Link } from "react-router-dom";
import GetAuth from "../API/GetAuth"
import { useFetching } from "../hooks/UseFetching";
import CodeInputVerify from "./components/CodeInputVerify";

const Auth = () => {

    const {windowWidth} = useContext(Context)
    // const [code, setCode] = useState('')
    // const [timeLeft, setTimeLeft] = useState(null); //seconds
    // const [codeErrorMes, setCodeErrorMes] = useState(''); 

    const navigate = useNavigate()
    const location = useLocation()

    // const attempts_left = location.state?.attempts_left

    const isLogin = location.pathname.replace(/\/$/, '').toLowerCase() === `${Login_Root.replace(/\/$/, '').toLowerCase()}`
    // const isRegistration = location.pathname.replace(/\/$/, '').toLowerCase() === `${Registration_Root.replace(/\/$/, '').toLowerCase()}`
    // const isPasswordRecovery = location.pathname.replace(/\/$/, '').toLowerCase() === `${Password_recovery_Root.replace(/\/$/, '').toLowerCase()}`
    // const isPasswordNew = location.pathname.replace(/\/$/, '').toLowerCase() === `${Password_new_Root.replace(/\/$/, '').toLowerCase()}`
    // const isEmailVerify = location.pathname.replace(/\/$/, '').toLowerCase() === `${Email_verify_Root.replace(/\/$/, '').toLowerCase()}`

    // // получаем и задаём время окончания кода
    // useEffect(() => {
    //     setCode('')
    //     setCodeErrorMes('')
    //     if( isEmailVerify) {
    //         const currentDate = new Date()
    //         const secondDate = new Date(location.state?.confirmation_code_expires)
    //         const differenceInMillis  = secondDate.getTime() - currentDate.getTime();
    //         const differenceInSeconds = Math.floor(differenceInMillis / 1000);
    //         setTimeLeft(differenceInSeconds)
    //     }
    // },[location.pathname])

    // useEffect(() => {
    //     if(timeLeft > 0) {
    //         const timer = setInterval(() => {
    //             setTimeLeft(prevTime => prevTime - 1);
    //         }, 1000);
            
    //         if(timeLeft === 0) {
    //             clearInterval(timer)
    //         }
    //         // Очищаем интервал при размонтировании компонента
    //         return () => clearInterval(timer);
    //     }
    // }, [timeLeft]);

    // // Функция для форматирования времени в формат mm:ss
    // const formatTime = (time) => {
    //     const minutes = Math.floor(time / 60);
    //     const seconds = time % 60;
    //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    // };
    // токен
    const [GetToken] = useFetching( async (email, password) => {
        const res = await GetAuth.GetToken(email, password)
        // console.log('GetToken complete:' , res.data)
        navigate(`/admin`)
        return res;
    })
    // // регистрация
    // const [SignUp] = useFetching( async (username, password, email) => {
    //     const res = await GetAuth.SignUp(username, password, email)
    //     // console.log('SignUp complete:' , res.data)
    //     navigate(`${Email_verify_Root}`, {state: {isRecovery: false, email: res.data.email, username: res.data.username, attempts_left: res.data.attempts_left, confirmation_code_expires: res.data.confirmation_code_expires}})
    //     return res;
    // })
    // // Проверить код (Регистрация)
    // const [InputCode, inputCodeLoading, inputCodeError] = useFetching( async (username, code) => {
    //     const res = await GetAuth.InputCode(username, code)
    //     // console.log('InputCode complete:' , res.data)
    //     navigate(`${Login_Root}`)
    //     return res;
    // })
    // // Отправить код для смены пароля
    // const [RecoveryVerificationCode] = useFetching( async (email) => {
    //     const res = await GetAuth.RecoveryVerificationCode(email)
    //     // console.log('RecoveryVerificationCode complete:' , res.data)
    //     navigate(`${Email_verify_Root}`, {state: {isRecovery: true, email: res.data.email, username: res.data.username, attempts_left: res.data.attempts_left, confirmation_code_expires: res.data.confirmation_code_expires}})
    //     return res;
    // })
    // // Проверить код (Изменение пароля)
    // const [RecoveryInputCode, RecoveryInputCodeLoading, RecoveryInputCodeError] = useFetching( async (email, code) => {
    //     const res = await GetAuth.RecoveryInputCode(email, code)
    //     // console.log('RecoveryInputCode complete:' , res.data)
    //     const username = location.state?.username
    //     navigate(`${Password_new_Root}`, {state: {username: username, code: code}})
    //     return res;
    // })
    // // Изменить пароль пользователя
    // const [RecoveryPassword] = useFetching( async (username, code, password) => {
    //     const res = await GetAuth.RecoveryPassword(username, code, password)
    //     // console.log('RecoveryPassword complete:' , res.data)
    //     navigate(`${Login_Root}`)
    //     return res;
    // })
    
    // useEffect(() => {
    //     if(inputCodeError.response?.data?.mes) {
    //         setCodeErrorMes(inputCodeError.response.data.mes)
    //     }
    //     if(RecoveryInputCodeError.response?.data?.mes) {
    //         setCodeErrorMes(RecoveryInputCodeError.response.data.mes)
    //     }
    // }, [inputCodeError, RecoveryInputCodeError])
    
    function submit(e) {
        e.preventDefault()
        if (isLogin) {
            const email = e.target.elements.login.value
            const password = e.target.elements.pass.value
            if(email !== '' && password !== '') {
                GetToken(email, password)
            }
        }
        // if (isRegistration) {
        //     const username= e.target.elements.nickname_reg.value
        //     const email= e.target.elements.login_reg.value
        //     const pass= e.target.elements.pass_reg.value
        //     const pass1 = e.target.elements.pass1_reg.value
            
        //     if(pass === pass1) {
        //         SignUp(username, pass, email)
        //     } else {
        //         alert('пароли не совпадают')
        //     }
        // }
        // if (isEmailVerify) {
        //     const username = location.state?.username
        //     const email = location.state?.email
        //     const isRecovery = location.state?.isRecovery

        //     if (isRecovery) {
        //         if(code.length >= 3) {
        //             RecoveryInputCode(email, code)
        //         } else {
        //             alert('Код введён не полностью')
        //         }
        //     } else {
        //         if(code.length >= 3) {
        //             InputCode(username, code)
        //         } else {
        //             alert('Код введён не полностью')
        //         }
        //     }

        // }
        // if (isPasswordRecovery) {
        //     const email= e.target.elements.login_recovery.value
        //     if(email !== '') {
        //         RecoveryVerificationCode(email)
        //     }
        // }
        // if (isPasswordNew) {
        //     const username = location.state?.username
        //     const codeState = location.state?.code
        //     const pass= e.target.elements.pass_reg.value
        //     const pass1= e.target.elements.pass1_reg.value
        //     if(pass === pass1) {
        //         RecoveryPassword(username, codeState, pass)
        //     } else {
        //         alert('пароли не совпадают')
        //     }
        // }
    }

    return (
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content b-c">
                    <div className="auth-block">
                        <div className="auth-content">
                            {/* <div className="txtc h-26-600-sf ">{isLogin ? 'Авторизация' : isRegistration ? 'Регистрация' : isPasswordRecovery ? 'Восстановление пароля' : isPasswordNew ? 'Введите новый пароль' : isEmailVerify ? 'Подтверждение почты' : ''}</div> */}
                            <div className="txtc h-26-600-sf ">{isLogin ? 'Авторизация' : ''}</div>
                            {isLogin &&
                            <form onSubmit={submit}>
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Email</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_mail_icon} alt="icon" />
                                        <input name="login" className="h-16-400-i gr5" placeholder="Введите email" type="email" required/>
                                        <img src={img_pack.input_help_icon} alt="icon" />
                                    </div>
                                </div>  
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Пароль</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_unlock_icon} alt="icon" />
                                        <input name="pass" className="h-16-400-i gr5" placeholder="Введите пароль" type="password" required/>
                                    </div>
                                </div>
                                <button type="submit" className="base-submit h-14-600-i white">Войти</button>
                            </form>
                            }
                            {/* {isRegistration &&
                            <form onSubmit={submit}>
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Никнейм</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_mail_icon} alt="icon" />
                                        <input name="nickname_reg" className="h-16-400-i gr5" placeholder="Введите никнейм" type="text" required/>
                                        <img src={img_pack.input_help_icon} alt="icon" />
                                    </div>
                                </div>  
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Email</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_mail_icon} alt="icon" />
                                        <input name="login_reg" className="h-16-400-i gr5" placeholder="Введите email" type="email" required/>
                                        <img src={img_pack.input_help_icon} alt="icon" />
                                    </div>
                                </div>  
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Пароль</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_unlock_icon} alt="icon" />
                                        <input name="pass_reg" className="h-16-400-i gr5" placeholder="Введите пароль" type="password" required/>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Пароль еще раз</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_unlock_icon} alt="icon" />
                                        <input name="pass1_reg" className="h-16-400-i gr5" placeholder="Введите пароль еще раз" type="password" required/>
                                    </div>
                                </div>
                                <button type="submit" className="base-submit h-14-600-i white">Зарегистрироваться</button>
                            </form>
                            }
                            {isPasswordRecovery &&
                            <form onSubmit={submit}>
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Email</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_mail_icon} alt="icon" />
                                        <input name="login_recovery" className="h-16-400-i gr5" placeholder="Введите email" type="email" required/>
                                        <img src={img_pack.input_help_icon} alt="icon" />
                                    </div>
                                </div>  
                                <button type="submit" className="base-submit h-14-600-i white">Восстановить</button>
                            </form>
                            }
                            {isPasswordNew &&
                            <form onSubmit={submit}>
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Пароль</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_unlock_icon} alt="icon" />
                                        <input name="pass_reg" className="h-16-400-i gr5" autoComplete="off" placeholder="Введите пароль" type="password" required/>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <span className="h-14-500-i gr8">Пароль еще раз</span>
                                    <div className="input_wi">
                                        <img src={img_pack.input_unlock_icon} alt="icon" />
                                        <input name="pass1_reg" className="h-16-400-i gr5" autoComplete="off" placeholder="Введите пароль еще раз" type="password" required/>
                                    </div>
                                </div>
                                <button type="submit" className="base-submit h-14-600-i white">Войти</button>
                            </form>
                            }
                            {isEmailVerify &&
                            <form onSubmit={submit} style={{gap: '30px'}}>
                                <span className="h-14-500-i gr5 txtc">На вашу почту был отправлен проверочный код.</span>
                                <CodeInputVerify code={code} setCode={setCode} />
                                <div className="col-5">
                                    <span className="h-14-400-sf c-1 txtc">{codeErrorMes ? `${codeErrorMes}` : `Осталось попыток ввода: ${attempts_left}`}</span>
                                    <span className="h-14-400-sf c-1 txtc">Время до окончания действия кода: {(timeLeft !== null && timeLeft > 0) ? formatTime(timeLeft) : '00:00' }</span>
                                    <button type="button" className="txtc underline h-12-400-i">Получить новый код подтверждения</button>
                                </div>
                                <button type="submit" className="base-submit h-14-600-i white">Подтвердить</button>
                            </form>
                            } */}
                            {/* {isLogin &&
                            <div className="row-20-js">
                                <Link to={`${Registration_Root}`} className="h-14-400-sf gr5">Зарегистрироваться</Link>
                                <Link to={`${Password_recovery_Root}`} className="h-14-400-sf gr5">Забыл пароль</Link>
                            </div>      
                            }
                            {isRegistration &&
                                <Link to={`${Login_Root}`} className="h-14-400-sf gr5 txtc">У меня уже есть аккаунт. Пройти авторизацию</Link>
                            } */}
                        </div>
                        {/* {windowWidth > 1100 &&
                        <div className="auth-img-container">
                            <img src={img_pack.auth_img} alt="img" />
                        </div>
                        } */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Auth;