import {React, useRef, useEffect} from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import img_pack from "../../img/img"
import { useFetching } from "../../hooks/UseFetching"
import GetFeedback from "../../API/GetFeedback"
import { PrivacyPolicyRoot } from "../../utils/consts"

const ContactPo = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const prevLocation = location.state?.prevLocation
    const contactPoData = location.state?.contactPoData

    function submit(e) {
        e.preventDefault()
        const formData = new FormData();
        formData.append("name", e.target.elements.name.value);
        formData.append("email", e.target.elements.email.value);
        formData.append("tel", e.target.elements.tel.value);
        formData.append("comment", 'Дополнительный комментарий');
        formData.append("status", 'NEW');

        AddNewContactRequest(formData)
    }

    const [AddNewContactRequest] = useFetching( async (data) => {
        const res = await GetFeedback.AddNewContactRequest(data)
        // console.log('AddNewContactRequest complete:' , res.data)
        confirm()
        return res;
    })

    function confirm() {
        navigate(`${prevLocation.pathname}`, {state: {confirmPo: true}})
    }

    return(
        <div className="contact_po">
            <span className="h-26-600-sf  gr7">Заказать звонок</span>
            <span className="h-14-400-sf gr6">Оставьте свои контактные данные и наш эксперт свяжется с вами в ближайшие минуты</span>
            <form action="#!" onSubmit={submit}>
                <div className="col-5">
                    <span className="h-14-500-i gr8">Напишите ваше имя</span>
                    <div className="input_wi">
                        <img src={img_pack.input_user_icon} alt="icon" />
                        <input name="name" className="h-16-400-i gr5" placeholder="Введите имя" type="text" required/>
                        {/* <img src={img_pack.input_help_icon} alt="icon" /> */}
                    </div>
                </div>  
                <div className="col-5">
                    <span className="h-14-500-i gr8">Email</span>
                    <div className="input_wi">
                        <img src={img_pack.input_mail_icon} alt="icon" />
                        <input name="email" className="h-16-400-i gr5" placeholder="Введите Email" type="email" required/>
                        {/* <img src={img_pack.input_help_icon} alt="icon" /> */}
                    </div>
                </div>  
                <div className="col-5">
                    <span className="h-14-500-i gr8">Телефон</span>
                    <div className="input_wi">
                        <img src={img_pack.input_phone_icon} alt="icon" />
                        <input name="tel" className="h-16-400-i gr5" placeholder="Введите телефон" type="tel" required/>
                        {/* <img src={img_pack.input_help_icon} alt="icon" /> */}
                    </div>
                </div>  
                <button className="admin_save_btn white h-14-600-i" type="submit">Перезвоните мне!</button>
                <div className="h-14-400-sf gr6 txtc">Нажимая “Перезвоните мне”, вы соглашаетесь с <Link to={PrivacyPolicyRoot} className="underline h-12-400-i gr8">политикой конфиденциальности.</Link></div>
            </form>
        </div>
    )
} 

export default ContactPo