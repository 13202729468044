import React, { useContext, useEffect, useState } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AdmSide_link from "./components/Cutom_admSide_link";
import img_pack from "../img/img";
import { AdminGeneralRoot, AdminMediafilesRoot, AdminStoreBrandsRoot, AdminStoreCatRoot, AdminStoreCouponsRoot, AdminStoreRoot, AdminContactRoot, AdminContactSettingsRoot, AdminOrderRoot, AdminSettingsRoot, AdminOrderSettingsRoot, AdminStoreBrandsCatRoot, AdminStoreImportRoot, AdminUsersRoot, AdminUsersRolesRoot } from "../utils/consts";
import GetAuth from "../API/GetAuth";
import { useFetching } from "../hooks/UseFetching";

const AdminLayout = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [resAuth, setResAuth] = useState([]);

    const [Logout] = useFetching( async () => {
        const res = await GetAuth.Logout()
        // console.log('Logout complete:' , res.data)
        navigate('/')
        return res;
    })

    // Проверка авторизации
    const [GetAuth_status,is_loading,error] = useFetching(async () => {
        const res = await GetAuth.GetAuth_status();
        // console.log('GetAuth complete:', res.data);
        setResAuth(res.data)
        if(res.data?.role_data?.access_admin_panel === false) {
            navigate('/')
        }
    });

    useEffect(() => {
        if(error?.response?.data?.is_authenticated === false) {
            navigate('/login')
        }
    },[error])

    useEffect(() => {
        GetAuth_status()
    },[])

    return (
        <>
            {(resAuth.is_authenticated && resAuth.role_data?.access_admin_panel) &&
            <div className="admin_page">
                <div className="admin_header">
                    <div className="admin_header_row">
                        <div className="admin_logo">
                            <img src={img_pack.header_logo} alt="logo" />
                        </div>
                        <div className="h-20-600-i">Панель администратора</div>
                        <div className="admin_header_icons">
                            <button>
                                <img src={img_pack.notif_icon} alt="notif_icon" />
                            </button>
                            <button onClick={() => Logout()}>
                                <img src={img_pack.logout_icon} alt="logout_icon" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="admin_main">
                    <div className="admin_sidebar">
                        <div className="admin_sidebar_col h-14-600-sf">
                            <AdmSide_link seRoot={AdminGeneralRoot} mainTitle={'Общая сводка'} mainIcon={<img_pack.admSide_general_icon/>}></AdmSide_link>
                            <AdmSide_link mainRoot={AdminStoreRoot} mainTitle={'Магазин'} mainIcon={<img_pack.admSide_shop_icon/>} >
                                <Link to={AdminStoreRoot} className={`${location.pathname === AdminStoreRoot ? 'active' : ''}`}>Все товары</Link>
                                <Link to={AdminStoreCatRoot} className={`${location.pathname === AdminStoreCatRoot ? 'active' : ''}`}>Категории</Link>
                                <Link to={AdminStoreBrandsRoot} className={`${location.pathname === AdminStoreBrandsRoot ? 'active' : ''}`}>Бренды</Link>
                                <Link to={AdminStoreBrandsCatRoot} className={`${location.pathname === AdminStoreBrandsCatRoot ? 'active' : ''}`}>Категории брендов</Link>
                                <Link to={AdminStoreImportRoot} className={`${location.pathname === AdminStoreImportRoot ? 'active' : ''}`}>Импорт</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={AdminOrderRoot} mainTitle={'Заказы'} mainIcon={<img_pack.admSide_orders_icon/>} >
                                <Link to={AdminOrderRoot} className={`${location.pathname === AdminOrderRoot ? 'active' : ''}`}>Все заказы</Link>
                                <Link to={AdminStoreCouponsRoot} className={`${location.pathname === AdminStoreCouponsRoot ? 'active' : ''}`}>Купоны</Link>
                                <Link to={AdminOrderSettingsRoot} className={`${location.pathname === AdminOrderSettingsRoot ? 'active' : ''}`}>Настройки</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={AdminContactRoot} mainTitle={'Обратная связь'} mainIcon={<img_pack.admSide_send_icon/>} >
                                <Link to={AdminContactRoot} className={`${location.pathname === AdminContactRoot ? 'active' : ''}`}>Заявки</Link>
                                <Link to={AdminContactSettingsRoot} className={`${location.pathname === AdminContactSettingsRoot ? 'active' : ''}`}>Настройки</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={AdminMediafilesRoot} mainTitle={'Медиафайлы'} mainIcon={<img_pack.admSide_media_icon/>}></AdmSide_link>
                            <AdmSide_link mainRoot={AdminUsersRoot} mainTitle={'Пользователи'} mainIcon={<img_pack.admSide_users_icon/>}>
                                <Link to={AdminUsersRoot} className={`${location.pathname === AdminUsersRoot ? 'active' : ''}`}>Все пользователи</Link>
                                <Link to={AdminUsersRolesRoot} className={`${location.pathname === AdminUsersRolesRoot ? 'active' : ''}`}>Роли</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={AdminSettingsRoot} mainTitle={'Настройки'} mainIcon={<img_pack.admSide_settings_icon/>}>
                                <Link to={AdminSettingsRoot} className={`${location.pathname === AdminSettingsRoot ? 'active' : ''}`}>Общие</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={'#!'} mainTitle={'Помощь'} mainIcon={<img_pack.admSide_help_icon/>}></AdmSide_link>
                        </div>
                        {/* <div className="admin_sidebar_col h-14-600-sf">
                            <span className="h-14-800-i c-1 txtc">AEGIS CORE</span>
                            <span className="h-12-400-i c-6 txtc">Версия: 1.0.0</span>
                        </div> */}
                    </div>
                    <div className="admin_content">
                        <Outlet />
                    </div>
                </div>
            </div>}
        </>
    )
}

export default AdminLayout;