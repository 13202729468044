import React, { useContext, useEffect, useRef, useState } from "react"
import { useFetching } from "../hooks/UseFetching";
import GetMain from "../API/GetMain";
import globalFunction from "../utils/consts";
import DateRangePicker from "./components/DateRangePicker";
import { format, subMonths } from "date-fns";
import { debounce } from 'lodash';

const AdminGeneralPage = () => {

    const [indicators, setIndicators] = useState([]);
    const [dateRange, setDateRange] = useState([subMonths(new Date(), 1), new Date()]);

    // ! Получить дашборд
    const [GetDashboard] = useFetching( async (data) => {
        const res = await GetMain.GetDashboard(data)
        // console.log('GetDashboard complete:' , res.data)
        setIndicators(res.data.indicators)
    })

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("date_in", format(new Date(dateRange[0]), 'yyyy-MM-dd'));
        formdata.append("date_out", format(new Date(dateRange[1]), 'yyyy-MM-dd'));
        GetDashboard(formdata);
    },[])

    const prevDateRange = useRef(dateRange);
    useEffect(() => {
        if (prevDateRange.current !== dateRange && dateRange[0] !== null && dateRange[1] !== null) {
            // console.log(dateRange)
            debouncedSubmit(dateRange)
            prevDateRange.current = dateRange
        }
    }, [dateRange])

    const debouncedSubmit = debounce((range) => {
        if (range) {
            const formdata = new FormData();
            formdata.append("date_in", format(new Date(range[0]), 'yyyy-MM-dd'));
            formdata.append("date_out", format(new Date(range[1]), 'yyyy-MM-dd'));
            GetDashboard(formdata);
        }
      }, 500); // 300 мс ожидания после последнего ввода

    return (
        <div className="col-20">
            <div className="row-20-js">
                <div className="h-16-500-i" style={{padding: '10px'}}>Общая сводка {`${dateRange[0]!==null ? format(new Date(dateRange[0]), 'dd.MM.yyyy') : '?'} - ${dateRange[1]!== null ? format(new Date(dateRange[1]), 'dd.MM.yyyy') : '?'}`}</div>
                <DateRangePicker dateRange={dateRange} setDateRange={setDateRange}/>
            </div>
            <div className="dashboard-row admin_content_base">
            {indicators?.length > 0 && indicators.map((el, i) => (
            <div key={`${el.name}-${i}`} className="dashboard-indicator">
                <span className="h-14-500-i gr7">{el.name}</span>
                <span className="h-32-500-i gr7">
                    {(() => {
                        const valueString = String(el.value); // Преобразуем в строку
                        const [value, currency] = valueString.split(' ') || [];
                        return `${globalFunction.withSpaces(globalFunction.split(value)[0])} ${currency || ''}`;
                    })()}
                </span>
            </div>
            ))}

            </div>  
            
        </div>
    )
}

export default AdminGeneralPage;