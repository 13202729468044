import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const PaymentAndDeliveryPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Способы оплаты</div>
                            <div className="h-14-400-i c-2">Мы предлагаем несколько удобных способов оплаты, чтобы вы могли выбрать наиболее подходящий для вас:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'decimal', marginLeft: '20px'}}>
                                <li><span className="h-14-700-i">Банковские карты</span><br/>
                                    Вы можете оплатить заказ с помощью дебетовой или кредитной карты. Все платежи осуществляются через защищенный платежный шлюз, который обеспечивает безопасность транзакций.<br/>
                                </li>
                                <li><span className="h-14-700-i">Оплата по QR коду</span><br/>
                                    Вы можете оплатить заказ с помощью QR кода по СБП (система быстрых платежей).
                                </li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Условия оплаты</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Все платежи обрабатываются в защищённом режиме, и ваши данные находятся под надежной защитой.</li>
                                <li>Оплата осуществляется в национальной валюте (рубли РФ).</li>
                                <li>Возврат денежных средств осуществляется в течение 14 дней с момента подачи заявки на возврат. Возврат осуществляется на тот же платёжный метод, которым была произведена оплата.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Самовывоз с ПВЗ</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Самовывоз заказа из Пункта Выдачи Заказов возможен в пн-пт: с 10:00-19:00; сб: с 10:00-18:00; вс: с 10:00-17:00;</li>
                                <li>Обязательно дождитесь сообщения, что заказ готов к выдаче;</li>
                                <li>В некоторых случаях может потребоваться предоплата, если стоимость заказа окажется большой, или для удаленных регионов.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Доставка транспортными компаниями</div>
                            <div className="h-14-400-i c-2">Осуществляется по всей территории РФ. Передаём товар в транспортную компанию после оплаты и поставки товар в г. Челябинск. Доставка до адреса магазина в г. Челябинск бесплатно. Клиентом оплачивается стоимость доставки до пункта выдачи ТК при получении.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default PaymentAndDeliveryPage;